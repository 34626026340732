import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormInput from '../../components/form-input';
import FullLogo from '../../resources/images/full_logo.svg';
import apiWrapper from '../../api';
import './styles.css';

const InvitationPassword = ({ history }) => {
  const [message] = useState('Welcome to Spatial Vis! Please set a password for your account.');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState();
  const { username } = useParams();
  const [userExists, setUserExists] = useState(false);
  const [course, setCourse] = useState();
  const userExistsMessage = [`It looks like you already have an account under ${username}. We have enrolled you in ${course}. If you are on a computer, log in below to access the web app. If you are on a mobile device, download the iOS or Android mobile app and log in.`,
    `If you forgot your password to ${username}, please use the Forgot Password button on the app log in screen. If you have any questions feel free to email info@egrove.education.`];
  useEffect(() => {
    apiWrapper.enrollExistingUser(username).then((res) => {
      setCourse(res.data.name);
      setUserExists(true);
    }).catch((err) => {
      setUserExists(false);
    });
  }, [username]);
  const onValueChange = (value, id) => {
    switch (id) {
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('The password and its confirmation do not match');
      return;
    }
    if (!userExists) {
      apiWrapper.acceptStudentInvitation(username, password).then(() => {
        history.push('/invitation', { success: true });
      }).catch((err) => {
        setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
      });
    }
  };

  return (
    <div className="main-container">
      <div className="form-width">
        <img src={FullLogo} className="w-100" alt="Logo" />
        {error ? (<Alert className="text-center mt-3 p-1" variant="danger"><small>{`${error}.`}</small></Alert>)
          : (
            <>
              {!userExists ? (<Alert className="text-center mt-3 p-1" variant="success">{message}</Alert>)
                : history.replace('/sign-in', { flash: { first: userExistsMessage[0], second: userExistsMessage[1]} })
              }
            </>
          )}
        {!userExists
              && (
              <Form onSubmit={onSubmit} className="w-100 mt-3">
                <FormInput
                  type="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onValueChange={onValueChange}
                />
                <FormInput
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onValueChange={onValueChange}
                />
                <Button variant="success" className="w-100 mt-3 button-style" type="submit">
                      Submit
                </Button>
              </Form>
              )}
      </div>
    </div>
  );
};

export default InvitationPassword;
