/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import CheckIcon from '@material-ui/icons/Check';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faEllipsisH, faCircle } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
// import { Icon } from '@iconify/react';
// import lassoIcon from '@iconify-icons/mdi/lasso';
import Draggable from 'react-draggable';

const CanvasToolbar = ({
  undo, redo, clear, setDrawingMode, changeEraseSize, submit, isCorrect, hintPeekData, toggleHintPeekDisplay, assignmentId, onUpdateSketchTimer, brushRadius, sketchTimer,
}) => {
  const [drawMode, setDrawMode] = useState(true);
  const [eraseMode, setEraseMode] = useState(false);
  const [eraseSize, setEraseSize] = useState(1);
  const [height, setHeight] = useState(window.innerHeight);
  const setMode = (mode) => {
    setDrawingMode(mode);
    if (mode === 'ERASE') changeEraseSize(eraseSize * brushRadius);
    else changeEraseSize();
  };

  const switchEraseSize = (size) => {
    setEraseSize(size);
    changeEraseSize(size * brushRadius);
  };

  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, [fn, ms]);
      }, ms);
    };
  };

  const onDrawClick = () => {
    setMode('DRAW');
    setDrawMode(true);
    setEraseMode(false);
  };

  const onEraserClick = () => {
    if (eraseSize === 0) setMode('LASSO');
    else {
      setMode('ERASE');
      setDrawMode(false);
      setEraseMode(true);
    }
  };

  const onEraserSizeClick = (size) => {
    setMode('ERASE');
    switchEraseSize(size);
  };

  const onClear = () => {
    clear();
    onDrawClick();
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setHeight(window.innerHeight);
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    if (hintPeekData.displayed) {
      setMode('DRAW');
      setDrawMode(true);
      setEraseMode(false);
    }
  }, [hintPeekData]);

  useEffect(() => {
    onUpdateSketchTimer();
    onDrawClick();
    switchEraseSize(1);
  }, [assignmentId]);

  const adjustDrawEraseStyles = (mode) => {
    if (hintPeekData.displayed) return '#808080';
    if (mode === 'DRAW') return 'white';
    return '#2e92c7';
  };

  const renderSubmitOrContinue = () => {
    if (hintPeekData.displayed) {
      return (
        <Button
          variant="warning"
          onTouchStart={() => { toggleHintPeekDisplay(false); }}
          onClick={() => { toggleHintPeekDisplay(false); }}
          style={{
            display: 'grid',
            backgroundColor: '#fcb702',
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .4)',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          Continue
        </Button>
      );
    }
    return (
      <Button
        variant="warning"
        onTouchStart={() => { submit(); }}
        onClick={() => { submit(); }}
        style={{
          display: 'grid',
          color: 'black',
          backgroundColor: '#fcb702',
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .4)',
          fontWeight: 'bold',
        }}
      >
        Submit
      </Button>
    );
  };

  return (
    <Draggable
      bounds={{
        top: height < 705 ? -10 : -120,
        left: -680,
        right: 100,
        bottom: height < 705 ? 350 : 450,
      }}
    >
      <ButtonToolbar
        style={{
          width: 'fit-content',
          backgroundColor: '#31b2f6',
          borderRadius: '12px',
          paddingLeft: '6px',
          paddingRight: '6px',
          paddingTop: '12px',
          paddingBottom: '12px',
          display: 'grid',
          position: 'absolute',
          zIndex: 100,
          top: '2%',
          left: '90%',
        }}
      >
        <ButtonGroup vertical style={{ backgroundColor: hintPeekData.displayed ? '#808080' : '#2e92c7', borderRadius: '8px' }}>
          <Button
            onTouchStart={() => { onDrawClick(); }}
            onClick={() => { onDrawClick(); }}
            disabled={hintPeekData.displayed}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'black',
              backgroundColor: drawMode ? adjustDrawEraseStyles('DRAW') : '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <CreateIcon fontSize="large" style={{ color: 'black' }} />
            <div style={{ fontSize: 12 }}>Pen</div>
          </Button>
          <Button
            onTouchStart={() => { onEraserClick(); }}
            onClick={() => { onEraserClick(); }}
            disabled={hintPeekData.displayed}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'black',
              backgroundColor: eraseMode ? 'white' : adjustDrawEraseStyles('ERASE'),
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <FontAwesomeIcon size="2x" icon={faEraser} style={{ color: 'black' }} />
            <div style={{ fontSize: 12 }}>Eraser</div>
          </Button>
        </ButtonGroup>
        <ButtonGroup vertical style={{ backgroundColor: '#31b2f6', borderRadius: '8px', alignItems: 'center' }}>
          <FontAwesomeIcon style={{ marginBottom: '-4px' }} size="sm" icon={faEllipsisH} color="#1e719b" />
          <FontAwesomeIcon style={{ marginTop: '-4px' }} size="sm" icon={faEllipsisH} color="#1e719b" />
        </ButtonGroup>
        {eraseMode && (
        <ButtonGroup vertical style={{ backgroundColor: '#2e92c7', borderRadius: '8px' }}>
          <Button
            onTouchStart={() => { onEraserSizeClick(1); }}
            onClick={() => { onEraserSizeClick(1); }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
              backgroundColor: eraseSize === 1 ? 'white' : '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <FontAwesomeIcon size="sm" icon={faCircle} style={{ color: 'black' }} />
            <div style={{ fontSize: 12 }}>Small</div>
          </Button>
          <Button
            onTouchStart={() => { onEraserSizeClick(2); }}
            onClick={() => { onEraserSizeClick(2); }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
              backgroundColor: eraseSize === 2 ? 'white' : '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <FontAwesomeIcon size="lg" icon={faCircle} style={{ color: 'black' }} />
            <div style={{ fontSize: 12 }}>Medium</div>
          </Button>
          <Button
            onTouchStart={() => { onEraserSizeClick(3); }}
            onClick={() => { onEraserSizeClick(3); }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
              backgroundColor: eraseSize === 3 ? 'white' : '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <FontAwesomeIcon size="2x" icon={faCircle} color="black" />
            <div style={{ fontSize: 12 }}>Large</div>
          </Button>
          {/* <Button
            onTouchStart={() => { switchEraseSize(0); setMode('LASSO'); }}
            onClick={() => { switchEraseSize(0); setMode('LASSO'); }}
            style={{
              color: 'black', backgroundColor: eraseSize === 0 ? 'white' : '#2e92c7', border: 'none', borderRadius: '8px', height: '60px',
            }}
          >
            <Icon icon={lassoIcon} width="35" height="35" />
          </Button> */}
          <Button
            onTouchStart={() => { onClear(); }}
            onClick={() => { onClear(); }}
            style={{
              color: 'black',
              backgroundColor: '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            Clear
          </Button>
        </ButtonGroup>
        )}
        {eraseMode && (
        <ButtonGroup vertical style={{ backgroundColor: '#31b2f6', borderRadius: '8px', alignItems: 'center' }}>
          <FontAwesomeIcon style={{ marginBottom: '-4px' }} size="sm" icon={faEllipsisH} color="#1e719b" />
          <FontAwesomeIcon style={{ marginTop: '-4px' }} size="sm" icon={faEllipsisH} color="#1e719b" />
        </ButtonGroup>
        )}
        <ButtonGroup vertical style={{ backgroundColor: hintPeekData.displayed ? '#808080' : '#2e92c7', borderRadius: '8px' }}>
          <Button
            onTouchStart={() => { undo(); }}
            onClick={() => { undo(); }}
            disabled={hintPeekData.displayed}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'black',
              backgroundColor: hintPeekData.displayed ? '#808080' : '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <UndoIcon fontSize="large" />
            <div style={{ fontSize: 12 }}>Undo</div>
          </Button>
          <Button
            onTouchStart={() => { redo(); }}
            onClick={() => { redo(); }}
            disabled={hintPeekData.displayed}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'black',
              backgroundColor: hintPeekData.displayed ? '#808080' : '#2e92c7',
              border: 'none',
              borderRadius: '8px',
              height: '60px',
            }}
          >
            <RedoIcon fontSize="large" />
            <div style={{ fontSize: 12 }}>Redo</div>
          </Button>
        </ButtonGroup>
        <ButtonGroup vertical style={{ backgroundColor: '#31b2f6', borderRadius: '8px', alignItems: 'center' }}>
          <FontAwesomeIcon style={{ marginBottom: '-4px' }} size="sm" icon={faEllipsisH} color="#1e719b" />
          <FontAwesomeIcon style={{ marginTop: '-4px' }} size="sm" icon={faEllipsisH} color="#1e719b" />
        </ButtonGroup>
        <ButtonGroup>
          {isCorrect ? (
            <>
              <Button
                variant="raised"
                disabled="true"
                style={{
                  backgroundColor: '#e4f5ff',
                }}
              >
                <CheckIcon
                  style={{
                    color: '#6abd32',
                    display: 'grid',
                  }}
                  fontSize="large"
                />
              </Button>
            </>
          ) : (
            <>
              { renderSubmitOrContinue() }
            </>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    </Draggable>
  );
};

export default CanvasToolbar;
