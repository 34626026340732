import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import WebAppLessonLayout from '../../components/web-app-layout/lesson';
import AssignmentTask from '../../components/assignment-task';
import './styles.css';
import { setCurrentSketchTime } from '../../utils/web-app';

const SpatialVisWebLesson = ({ location, history }) => {
  const [currentAssignment, setCurrentAssignment] = useState();
  const [currentAssignments, setCurrentAssignments] = useState([]);
  const [assignmentNumber, setAssignmentNumber] = useState(1);
  const [firstPage, setFirstPage] = useState(false);
  const [buttonLink, setButtonLink] = useState('');
  const [isSmallerScreen, setSmallerScreen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [taskStartTime, setTaskStartTime] = useState(new Date());
  const [timeOnTask, setTimeOnTask] = useState(0);
  const [timeToSketch, setTimeToSketch] = useState(0);
  const [didStartDrawing, setDidStartDrawing] = useState(false);

  const setAssignmentIndex = (assignmentIndex) => {
    setTimeToSketch(0);
    setTimeOnTask(0);
    setTaskStartTime(new Date());
    setDidStartDrawing(false);
    setAssignmentNumber(assignmentIndex);
    setCurrentAssignment(currentAssignments[assignmentIndex]);
    if (currentAssignments[assignmentIndex] === currentAssignments[0]) {
      setFirstPage(true);
      setAssignmentNumber(0);
    } else setFirstPage(false);
  };

  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const getTimeSpentOnTask = () => Math.round(((new Date() - taskStartTime) / 1000) + timeOnTask);

  const updateSketchTimerFromAssignment = (sketchTime) => {
    setTimeToSketch(timeToSketch + sketchTime);
    setCurrentSketchTime(timeToSketch + sketchTime);
  };

  const onCanvasChange = (didStart = true) => {
    setDidStartDrawing(didStart);
    if (didStart) setTaskStartTime(new Date());
  };

  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, [fn, ms]);
      }, ms);
    };
  };

  // User has switched back to the tab
  const onFocus = () => {
    setTaskStartTime(new Date());
  };

  // User has switched away from the tab
  const onBlur = () => {
    setTimeOnTask(Math.round((new Date() - taskStartTime) / 1000));
  };

  useEffect(() => {
    setSmallerScreen(window.innerWidth < 1440 || window.innerHeight < 702);
  }, [screenHeight, screenWidth, isSmallerScreen]);

  useEffect(() => {
    if (location.state && location.state.lesson) {
      const assignments = location.state.assignments.filter((item) => item.code.split('_')[0] === location.state.lesson.code).sort((a, b) => a.display_order - b.display_order);
      setCurrentAssignments(assignments);
      setButtonLink(assignments[0].intro_blocks.find((item) => item.block_kind === 'button_link').text);
    }
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return (
    <div style={{ height: '100vh', backgroundColor: '#e4f5ff', overflow: currentAssignment && currentAssignment.kind === 'tutorial' ? 'visible' : 'hidden' }}>
      <WebAppLessonLayout
        setAssignmentNumber={setAssignmentIndex}
        getTimeSpentOnTask={getTimeSpentOnTask}
        currentIndex={assignmentNumber}
        assignments={currentAssignments}
        assignment={currentAssignment}
        isSmallerScreen={isSmallerScreen}
        location={location}
        history={history}
        didStartDrawing={didStartDrawing}
      >
        <AssignmentTask
          className="assignment-task"
          setAssignmentNumber={setAssignmentIndex}
          getTimeSpentOnTask={getTimeSpentOnTask}
          updateSketchTime={updateSketchTimerFromAssignment}
          assignmentNumber={assignmentNumber}
          assignmentCount={currentAssignments.length - 1}
          assignment={currentAssignment}
          isSmallerScreen={isSmallerScreen}
          screenHeight={screenHeight}
          screenWidth={screenWidth}
          timeToSketch={timeToSketch}
          location={location}
          history={history}
          onCanvasChange={onCanvasChange}
        />
        <div style={{ backgroundColor: '#e4f5ff' }}>
          {firstPage
          && <Button onClick={() => { handleClick(buttonLink); }} className="lecture-button"><h5 style={{ marginBottom: 0 }}>View Lecture</h5></Button>}
        </div>
      </WebAppLessonLayout>
    </div>
  );
};

export default SpatialVisWebLesson;
