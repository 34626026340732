import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { authContext } from '../../contexts/auth-context';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';


const Courses = ({ history }) => {
  const { auth } = useContext(authContext);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    apiWrapper.getCourses().then((res) => {
      setCourses(res.data);
    });
  }, []);

  const onCourseEdit = (course) => {
    history.push('/courses/edit', { course });
  };

  const onCourseView = (courseId) => {
    history.push(`/courses/${courseId}/show`);
  };

  const renderCourses = () => courses.map((course) => (
    <tr key={course.id}>
      <td className="align-middle">{course.code}</td>
      <td className="align-middle">{course.name}</td>
      <td className="align-middle">{course.school ? course.school.name : ''}</td>
      <td className="align-middle">
        {`${moment(course.enrollment_start_date).format('ll')} - ${moment(course.enrollment_end_date).format('ll')}`}
      </td>
      <td className="align-middle">
        {`${moment(course.start_date).format('ll')} - ${moment(course.end_date).format('ll')}`}
      </td>
      <td className="d-flex justify-content-between">
        {
          auth.user.account_level === 'admin' && (
            <Button variant="primary" size="sm" onClick={() => { onCourseEdit(course); }}>
              Edit
            </Button>
          )
        }
        <Button variant="outline-primary" size="sm" onClick={() => { onCourseView(course.id); }}>
          View
        </Button>
      </td>
    </tr>
  ));

  return (
    <div>
      <MainLayout>
        <div className="d-flex justify-content-between mt-4">
          <h1> Courses </h1>
          {
            auth.user.account_level === 'admin' && (
              <Button variant="success" className="align-self-center button-style" onClick={() => { history.push('/courses/new'); }}>Add Course</Button>
            )
          }
        </div>
        <Table responsive className="mt-5">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>School</th>
              <th>Enrollment Dates</th>
              <th>Course Dates</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {renderCourses()}
          </tbody>
        </Table>
      </MainLayout>
    </div>
  );
};

Courses.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default Courses;
