/* eslint-disable max-len */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Row, Col, Button, Spinner,
} from 'react-bootstrap';
import './styles.css';
import StarIcon from '@material-ui/icons/Star';
import { Doughnut } from 'react-chartjs-2';
import Axios from 'axios';
import moment from 'moment';
import Canvas from '../canvas';
import CanvasToolbar from '../canvas-toolbar';
import HintPeekLegend from '../hint-peek-legend';
import { webAppContext } from '../../contexts/web-app-context';
import { deserializeSvg } from '../../utils/svg-deserializer';
import formatString from '../../utils/custom-string-formatter';
import AssignmentIntroBlock from '../assignment-intro-block';
import Modal from '../modal';
import apiWrapper from '../../api';
import getDefaultSubmission from '../../utils/get-default-submission';
import { currentSketchTime, setCurrentSketchTime } from '../../utils/web-app';

let chartLabel = '';

const MINI_HINTS = {
  CORRECT: 'Great job!',
  TRY_AGAIN: "Let's try that again.",
  INCORRECT_HIDDEN_LINES: 'Hidden line(s)\nincorrect',
  CLOSE: 'Close! Draw\nmore carefully',
  MISSING_LINE: 'You may be\nmissing a line',
  MISSING_LINES: 'You may be\nmissing lines',
  EXTRA_LINE: 'You may have\nan extra line',
  EXTRA_LINES: 'You may have\nextra lines',
};

const { Chart } = require('react-chartjs-2');

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw() {
    // eslint-disable-next-line prefer-rest-params
    originalDoughnutDraw.apply(this, arguments);

    const { chart } = this;
    const { width } = chart;
    const { height } = chart;
    const { ctx } = chart;

    const fontSize = 1;
    ctx.font = chartLabel !== MINI_HINTS.TRY_AGAIN && chartLabel !== MINI_HINTS.CORRECT ? `bold ${fontSize}em sans-serif` : `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';
    ctx.fillStyle = chartLabel !== MINI_HINTS.TRY_AGAIN && chartLabel !== MINI_HINTS.CORRECT ? 'red' : 'black';

    const text = chartLabel;
    // correctly implement newlines for chartlabel
    const lineheight = 15;
    const lines = text.split('\n');
    const textY = lines.length > 1 ? (height / 2) - 10 : height / 2;
    for (let i = 0; i < lines.length; i += 1) {
      const textX = Math.round((width - ctx.measureText(lines[i]).width) / 2);
      ctx.fillText(lines[i], textX, textY + (i * lineheight));
    }
  },
});

const AssignmentTask = ({
  setAssignmentNumber, getTimeSpentOnTask, updateSketchTime, assignmentNumber, assignmentCount, assignment, isSmallerScreen, screenHeight, screenWidth, timeToSketch, history, onCanvasChange,
}) => {
  const {
    submissions, updateSubmission, enrollment, requiredAssignments,
  } = useContext(webAppContext);

  const [introBlocks, setIntroBlocks] = useState([]);
  const submission = submissions?.find((sub) => sub.lesson_assignment_id === assignment?.id) ?? getDefaultSubmission(enrollment?.id, assignment?.id);
  const [savedSketch, setSavedSketch] = useState([]);
  const [stars, setStars] = useState([1, 1, 1]);
  const [gradingModalDisplayed, setGradingModalDisplayed] = useState(false);
  const [showRequiredAssignmentPrompt, setShowRequiredAssignmentPrompt] = useState(true);
  const [hintPeekData, setHintPeekData] = useState({
    displayed: false,
  });
  const [gradingPercentages, setGradingPercentages] = useState({
    correctPercent: 0,
    missingPercent: 0,
    incorrectPercent: 0,
  });
  const [gradingResultsLoading, setGradingResultsLoading] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);

  const required = requiredAssignments == null
                    || requiredAssignments.length <= 0
                    || requiredAssignments.find((assId) => assId === assignment?.id) != null;

  // Constants
  const GRADING_COLORS = {
    retry: '#569929',
    hint: '#f7b727',
    peek: '#dd3b31',
    correct: '#569929',
    missing: '#1E95C8',
    error: '#dd3b31',
  };

  useEffect(() => {
    setHintPeekData({
      displayed: false,
    });
    setShowRequiredAssignmentPrompt(true);
    setDidSubmit(false);
    setStars([1, 1, 1]);
    setGradingPercentages({
      correctPercent: 0,
      missingPercent: 0,
      incorrectPercent: 0,
    });
  }, [assignment]);

  useEffect(() => {
    const blocks = [];
    if (assignment && assignment.kind === 'tutorial') {
      assignment.intro_blocks.sort((a, b) => a.display_order - b.display_order).map((block) => {
        blocks.push({
          visited: false,
          kind: block.block_kind,
          img: block.image_uri,
          title: block.title,
          text: block.text,
          alt: block.alt_text,
          displayOrder: block.display_order,
        });
      });
      setIntroBlocks([...blocks]);
    } else if (assignment) {
      const sketch = submissions.find((item) => item.lesson_assignment_id === assignment.id);
      if (sketch) {
        fetch(sketch.sketch_uri)
          .then((response) => response.text())
          .then((svg) => {
            const lines = deserializeSvg(svg);
            setSavedSketch(lines);
          })
          .catch((error) => {
            console.log(error);
          });
      } else setSavedSketch([]);
    }
  }, [assignment, submissions, screenHeight, screenWidth]);

  const toggleModal = () => setGradingModalDisplayed(!gradingModalDisplayed);

  const toggleHintPeekDisplay = (displayed) => {
    setHintPeekData({
      ...hintPeekData,
      displayed,
    });
  };

  const updateAssignmentNumber = (number) => {
    toggleModal();
    if (number <= assignmentCount) setAssignmentNumber(number);
    else history.push('/spatial-vis-web');
  };

  const calculateStars = (responseData) => {
    if (!responseData) return;
    if (responseData.did_peek || submission.did_peek) setStars([1, 0, 0]);
    else if (responseData.did_look_at_hint || submission.did_look_at_hint) setStars([1, 1, 0]);
  };

  const onRetryHintPeek = (clicked) => {
    toggleModal();
    setHintPeekData({
      ...hintPeekData,
      displayed: clicked !== 'retry',
      type: clicked,
    });
    if (clicked === 'retry' && deserializeSvg(submission.sketch_svg).length === 0) {
      setCurrentSketchTime(0);
      toggleModal();
    } else {
      apiWrapper.submitAssignment({
        ...submission,
        did_peek: clicked === 'peek',
        did_look_at_hint: clicked === 'hint',
      }).then((rawSubmission) => {
        updateSubmission({
          ...rawSubmission.data,
        });
        calculateStars(rawSubmission.data);
        setCurrentSketchTime(0);
        toggleModal();
      });
    }
  };

  const updateSketchTimerFromCanvas = (sketchTime) => {
    updateSketchTime(sketchTime);
  };

  const onContinue = () => {
    updateSubmission({
      ...submission,
      sketched_at: moment().format(),
      created_at: moment(submission?.created_at).format(),
      updated_at: moment(submission?.updated_at).format(),
      was_submitted: false,
    });
    setCurrentSketchTime(0);
    updateAssignmentNumber(assignmentNumber + 1);
  };

  // API Calls

  const submitAssignment = (sketch, png, grade = true) => {
    setDidSubmit(true);
    if (grade) {
      setGradingResultsLoading(true);
      Axios.post(process.env.REACT_APP_GRADING_SERVICE, { png: png.split(',')[1], assignment }).then((rawResponse) => {
        const response = rawResponse.data;
        chartLabel = MINI_HINTS[response.hintMessage];

        calculateStars(response);

        setHintPeekData({
          correctImage: response.correctImage,
          missingImage: response.missingImage,
          addedImage: response.addedImage,
          displayed: false,
          type: undefined,
        });

        setGradingPercentages({
          correctPercent: response.correctPercent,
          incorrectPercent: response.incorrectPercent,
          missingPercent: response.missingPercent,
        });

        setGradingResultsLoading(false);
        toggleModal();

        if (response.correct) {
          apiWrapper.submitAssignment({
            ...submission,
            sketch_svg: sketch,
            is_correct: response.correct,
            did_peek: false,
            did_look_at_hint: false,
            grading_metrics: response.gradingMetrics,
            sketched_at: moment().format(),
            created_at: moment(submission?.created_at).format(),
            updated_at: moment(submission?.updated_at).format(),
            was_submitted: true,
            sketch_time: currentSketchTime,
            time_on_task: getTimeSpentOnTask(),
          }).then((rawSubmission) => {
            updateSubmission({
              ...rawSubmission.data,
            });
            setCurrentSketchTime(0);
          });
        } else {
          updateSubmission({
            ...submission,
            sketch_svg: sketch,
            is_correct: response.correct,
            did_peek: false,
            did_look_at_hint: false,
            grading_metrics: response.gradingMetrics,
            sketched_at: moment().format(),
            created_at: moment(submission?.created_at).format(),
            updated_at: moment(submission?.updated_at).format(),
            was_submitted: true,
            sketch_time: currentSketchTime,
            time_on_task: getTimeSpentOnTask(),
          });
          setCurrentSketchTime(0);
        }
      });
    }
  };

  // Rendering

  const renderIntroBlocks = (block, index) => {
    block.visited = false;
    return (
      <AssignmentIntroBlock
        key={block.id}
        introBlocks={introBlocks}
        block={block}
        index={index}
      />
    );
  };

  const renderStars = () => {
    let starArr = stars;
    if (submission.did_peek) starArr = [1, 0, 0];
    else if (submission.did_look_at_hint) starArr = [1, 1, 0];
    if (submission?.is_correct || gradingPercentages.correctPercent === 100) {
      return (
        <div className="stars">
          { starArr.map((star) => {
            if (star) return (<StarIcon fontSize="large" style={{ color: GRADING_COLORS.hint }} />);
            return (<StarIcon fontSize="large" style={{ color: '#555555' }} />);
          })}
        </div>
      );
    }
  };

  const renderNotRequiredOverlay = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      height: 640,
      width: 640,
      backgroundColor: '#00000055',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      fontSize: 20,
    }}
    >
      <p>This assignment isn&apos;t required.</p>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}
      >
        <Button
          variant="warning"
          className="feedback-button"
          onClick={() => {
            setShowRequiredAssignmentPrompt(false);
          }}
          style={{ backgroundColor: GRADING_COLORS.retry }}
        >
          Do Anyway
        </Button>
        <Button
          variant="warning"
          className="feedback-button"
          onClick={() => {
            setAssignmentNumber(assignmentNumber + 1);
          }}
          style={{ backgroundColor: GRADING_COLORS.peek }}
        >
          Skip
        </Button>
      </div>
    </div>
  );

  const renderGradingResults = () => {
    if (submission?.is_correct || gradingPercentages.correctPercent === 100) {
      chartLabel = MINI_HINTS.CORRECT;
      return (
        <div className="feedback-buttons">
          <Button
            variant="warning"
            className="feedback-button"
            onClick={() => {
              onContinue();
            }}
            style={{ backgroundColor: GRADING_COLORS.hint }}
          >
            Continue
          </Button>
        </div>
      );
    }
    return (
      <div className="percentages">
        <h5 style={{ fontSize: '1rem', color: GRADING_COLORS.correct }}>
          {gradingPercentages.correctPercent}
          % correct solution lines
        </h5>
        <h5 style={{ fontSize: '1rem', color: GRADING_COLORS.missing }}>
          {gradingPercentages.missingPercent}
          % missing solution lines
        </h5>
        <h5 style={{ fontSize: '1rem', color: GRADING_COLORS.peek }}>
          {gradingPercentages.incorrectPercent}
          % incorrect lines
        </h5>
        <div className="feedback-buttons">
          <Button variant="success" className="feedback-button" onClick={() => { onRetryHintPeek('retry'); }} style={{ backgroundColor: GRADING_COLORS.retry }}>
            Retry
          </Button>
          {!assignment?.hint_is_disabled && (
          <Button variant="warning" className="feedback-button" onClick={() => { onRetryHintPeek('hint'); }} style={{ backgroundColor: GRADING_COLORS.hint, color: 'black' }}>
            Hint
          </Button>
          )}
          {!assignment?.hint_is_disabled && (
          <Button variant="danger" className="feedback-button" onClick={() => { onRetryHintPeek('peek'); }} style={{ backgroundColor: GRADING_COLORS.peek }}>
            Peek
          </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: '#e4f5ff' }}>
      {assignment && (assignment.kind === 'solid' || assignment.kind === 'hidden')
        ? (
          <Row style={{ width: '100%' }}>
            <Col className="mt-4" xs={6} md={4}>
              <div style={{ textAlign: 'start' }}>
                <img alt={assignment.alt_text} className="content-img" style={{ background: 'white' }} src={assignment.content_image_uri} />
                {formatString(assignment.content_description)}
              </div>
            </Col>
            <Col id="canvas-col" xs={12} md={8} style={{ padding: 0 }}>
              <div style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
                bottom: isSmallerScreen && '9%',
                minHeight: isSmallerScreen ? 'calc(100vh)' : 'calc(100vh - 54px)',
                backgroundImage: `url(${assignment.grid_image_uri})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 1600,
                backgroundColor: 'white',
              }}
              >
                {assignment.hint_is_disabled && <h4 className="test-question-banner">Test question, no hints or peeks!</h4>}
                <Canvas
                  onChange={onCanvasChange}
                  savedSketch={savedSketch}
                  canvasWidth={640}
                  canvasHeight={640}
                  lessonAssignmentId={assignment.id}
                  onSubmit={submitAssignment}
                  hintPeekData={hintPeekData}
                  toggleHintPeekDisplay={toggleHintPeekDisplay}
                  isCorrect={submission.is_correct}
                  didHint={submission.did_look_at_hint}
                  didPeek={submission.did_peek}
                  didSubmit={didSubmit}
                  onUpdateSketchTimer={updateSketchTimerFromCanvas}
                  shouldHideSketch={hintPeekData.displayed && hintPeekData.type === 'peek'}
                  brushRadius={5}
                  style={
                    {
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                      outline: '1000px solid',
                      outlineColor: '#00000033',
                    }
                  }
                />
                { !required && showRequiredAssignmentPrompt && enrollment?.course?.assignment_set_id !== 0 && renderNotRequiredOverlay() }
                { hintPeekData.displayed && (
                  <>
                    <CanvasToolbar
                      undo={() => {}}
                      redo={() => {}}
                      clear={() => {}}
                      setDrawingMode={() => {}}
                      changeEraseSize={() => {}}
                      submit={() => {}}
                      isCorrect={false}
                      hintPeekData={hintPeekData}
                      toggleHintPeekDisplay={toggleHintPeekDisplay}
                      onUpdateSketchTimer={updateSketchTimerFromCanvas}
                    />
                    <HintPeekLegend hintPeekData={hintPeekData} />
                    <img
                      style={{
                        display: hintPeekData.type === 'peek' ? 'block' : 'none',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: 640,
                        width: 640,
                      }}
                      alt="Hint or Peek result"
                      src={`data:image/jpeg;charset=utf-8;base64, ${hintPeekData.missingImage}`}
                    />
                    <img
                      style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: 640,
                        width: 640,
                      }}
                      alt="Hint or Peek result"
                      src={`data:image/jpeg;charset=utf-8;base64, ${hintPeekData.correctImage}`}
                    />
                    <img
                      style={{
                        position: 'absolute',
                        display: hintPeekData.type === 'peek' ? 'block' : 'none',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: 640,
                        width: 640,
                      }}
                      alt="Hint or Peek result"
                      src={`data:image/jpeg;charset=utf-8;base64, ${hintPeekData.addedImage}`}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        )
        : (
          <>
            { introBlocks.length > 0 && introBlocks.map((block, index) => (
              renderIntroBlocks(block, index)
            ))}
          </>
        )}
      <>
        <Modal show={gradingModalDisplayed} handleClose={toggleModal} close="button" defaultButton={false} gradingStyle>
          <h4>Grading Results</h4>
          <Doughnut
            id="chart"
            width="400px"
            height="200px"
            data={{
              datasets: [
                {
                  data: [gradingPercentages.correctPercent, gradingPercentages.missingPercent, gradingPercentages.incorrectPercent],
                  backgroundColor: [GRADING_COLORS.correct, GRADING_COLORS.missing, !submission?.is_correct ? GRADING_COLORS.error : 'white'],
                  borderColor: [GRADING_COLORS.correct, GRADING_COLORS.missing, !submission?.is_correct ? GRADING_COLORS.error : 'white'],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              cutoutPercentage: 80,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
            }}
          />
          { renderStars() }
          { renderGradingResults()}
        </Modal>
        <Modal show={gradingResultsLoading} close="loading" gradingStyle>
          <Spinner className="center-loading" animation="grow" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Modal>
      </>
    </div>
  );
};

export default AssignmentTask;
