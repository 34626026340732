const getDefaultSubmission = (enrollmentId, assignmentId) => ({
  id: null,
  enrollment_id: enrollmentId,
  lesson_assignment_id: assignmentId,
  time_on_task: 0,
  was_submitted: false,
  is_correct: false,
  did_look_at_hint: false,
  did_peek: false,
  tz_offset: 0,
});

export default getDefaultSubmission;
