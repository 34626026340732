import axios from 'axios';

// creating a new instance of axios with a custom config: after 30 secs of unsuccessful connection => proceed to an error;
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

const buildInterceptor = (headers) => (config) => ({
  ...config,
  data: config.data,
  headers,
});

// intercepting request before sending it;
let requestInterceptor = axiosInstance.interceptors.request.use(
  buildInterceptor({}),
  (err) => Promise.reject(err),
);

export const setAxiosAuthorizationHeader = (authorization) => {
  // removing previous interceptor
  axiosInstance.interceptors.request.eject(requestInterceptor);
  requestInterceptor = axiosInstance.interceptors.request.use(
    buildInterceptor({ Authorization: authorization }),
    (err) => Promise.reject(err),
  );
};
