/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-else-return */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LazyBrush } from 'lazy-brush';
import StarIcon from '@material-ui/icons/Star';
import CanvasToolbar from '../canvas-toolbar';
import './styles.css';
import { serializeSvg } from '../../utils/svg-serializer';
import simplifyPath from '../../utils/line-straightener';
import { setCurrentSketch, setCurrentSketchTime } from '../../utils/web-app';

function midPointBtw(p1, p2) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  };
}

const canvasStyle = {
  display: 'block',
  position: 'absolute',
};

const canvasTypes = [
  {
    name: 'interface',
    zIndex: 15,
  },
  {
    name: 'drawing',
    zIndex: 11,
  },
  {
    name: 'temp',
    zIndex: 12,
  },
  {
    name: 'grid',
    zIndex: 10,
  },
];

const dimensionsPropTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

class Canvas extends PureComponent {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      onChange: PropTypes.func,
      lazyRadius: PropTypes.number,
      brushRadius: PropTypes.number,
      brushColor: PropTypes.string,
      catenaryColor: PropTypes.string,
      gridColor: PropTypes.string,
      backgroundColor: PropTypes.string,
      hideGrid: PropTypes.bool,
      canvasWidth: dimensionsPropTypes,
      canvasHeight: dimensionsPropTypes,
      disabled: PropTypes.bool,
      imgSrc: PropTypes.string,
      saveData: PropTypes.string,
      hideInterface: PropTypes.bool,
      savedSketch: PropTypes.array,
      lessonAssignmentId: PropTypes.number,
      didHint: PropTypes.bool,
      didPeek: PropTypes.bool,
      onSubmit: PropTypes.func,
      hintPeekData: PropTypes.object,
      toggleHintPeekDisplay: PropTypes.func,
      isCorrect: PropTypes.bool,
      didSubmit: PropTypes.bool,
      onUpdateSketchTimer: PropTypes.func,
      shouldHideSketch: PropTypes.bool,
    };

    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
      onChange: null,
      lazyRadius: 0,
      brushRadius: 5,
      brushColor: '#000000',
      catenaryColor: '#0a0302',
      gridColor: 'transparent',
      backgroundColor: 'transparent',
      hideGrid: false,
      canvasWidth: '100%',
      canvasHeight: '100%',
      disabled: false,
      imgSrc: '',
      saveData: '',
      hideInterface: false,
      savedSketch: [],
      lessonAssignmentId: 0,
      didHint: false,
      didPeek: false,
      onSubmit: () => {},
      hintPeekData: {},
      toggleHintPeekDisplay: () => {},
      isCorrect: false,
      didSubmit: false,
      onUpdateSketchTimer: () => {},
      shouldHideSketch: false,
    };

    constructor(props) {
      super(props);

      this.canvas = {};
      this.ctx = {};

      this.points = [];
      this.lines = [];
      this.redoStack = [];

      this.state = {
        index: 0,
      };

      this.linesFromClear = [];
      this.drawingMode = 'DRAW';
      this.mouseHasMoved = true;
      this.valuesChanged = true;
      this.isDrawing = false;
      this.isPressing = false;
      this.mounted = false;
      this.triggerSubmit = true;
      this.sketchUpdated = false;
      this.sketchLoaded = false;
      this.holdTime = 1000;
      this.tolerance = 30;
      this.sketchTimer = 0;
      this.sketchStartTime = 0;
    }

    componentDidMount() {
      setCurrentSketch([]);
      this.mounted = true;

      this.lazy = new LazyBrush({
        radius: this.props.lazyRadius * window.devicePixelRatio,
        enabled: true,
        initialPoint: {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
        },
      });
      this.chainLength = this.props.lazyRadius * window.devicePixelRatio;

      this.drawImage();
      this.loop();

      window.setTimeout(() => {
        const initX = window.innerWidth / 2;
        const initY = window.innerHeight / 2;
        this.lazy.update(
          { x: initX - this.chainLength / 4, y: initY },
          { both: true },
        );
        this.lazy.update(
          { x: initX + this.chainLength / 4, y: initY },
          { both: false },
        );
        this.mouseHasMoved = true;
        this.valuesChanged = true;
        this.clearCanvas();

        // Load saveData from prop if it exists
        if (this.props.saveData) {
          this.loadSaveData(this.props.saveData);
        }
        if (this.props.savedSketch.length > 0) this.loadSketch();
      }, 100);
    }

    componentDidUpdate(prevProps) {
      this.mounted = false;
      this.triggerSubmit = true;
      if (prevProps.lazyRadius !== this.props.lazyRadius) {
        // Set new lazyRadius values
        this.chainLength = this.props.lazyRadius * window.devicePixelRatio;
        this.lazy.setRadius(this.props.lazyRadius * window.devicePixelRatio);
      }

      if (prevProps.saveData !== this.props.saveData) {
        this.loadSaveData(this.props.saveData);
      }

      if (!this.props.didSubmit && prevProps.savedSketch !== this.props.savedSketch) {
        this.sketchTimer = 0;
        if (this.props.savedSketch.length > 0) {
          setCurrentSketchTime(0);
          this.clearCanvas();
          this.loadSketch();
          this.props.onChange(false);
        } else {
          setCurrentSketchTime(0);
          setCurrentSketch([]);
          this.clearCanvas();
          this.props.onChange(false);
        }
      }

      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        // Signal this.loop function that values changed
        this.valuesChanged = true;
      }

      this.mounted = true;

      let mouseTimeout;
      document.onmousemove = (e) => {
        clearTimeout(mouseTimeout);
        mouseTimeout = setTimeout(() => {
          if (this.isPressing) this.points = simplifyPath(this.points, this.tolerance, false);
        }, this.holdTime);
      };

      if (this.sketchUpdated
        && prevProps.lessonAssignmentId !== this.props.lessonAssignmentId) {
        this.submitSketch(false);
      }

      if (prevProps.lessonAssignmentId !== this.props.lessonAssignmentId) {
        this.sketchLoaded = false;
        this.redoStack = [];
      }

      this.sketchUpdated = false;
      if (this.lines.length > 0) this.setState({ index: this.lines.length - 1 });
      else this.setState({ index: 0 });
    }

    getSaveData = () =>
      // Construct and return the stringified saveData object
    // eslint-disable-next-line implicit-arrow-linebreak
      JSON.stringify({
        lines: this.lines,
        width: this.props.canvasWidth,
        height: this.props.canvasHeight,
      })
    ;

    loadSaveData = (saveData) => {
      if (typeof saveData !== 'string') {
        throw new Error('saveData needs to be of type string!');
      }

      const { lines, width, height } = JSON.parse(saveData);

      if (!lines || typeof lines.push !== 'function') {
        throw new Error('saveData.lines needs to be an array!');
      }

      this.clearCanvas();

      if (
        width === this.props.canvasWidth
            && height === this.props.canvasHeight
      ) {
        this.simulateDrawingLines({
          lines,
          edit: null,
        });
      } else {
        // we need to rescale the lines based on saved & current dimensions
        const scaleX = this.props.canvasWidth / width;
        const scaleY = this.props.canvasHeight / height;
        const scaleAvg = (scaleX + scaleY) / 2;

        this.simulateDrawingLines({
          lines: lines.map((line) => ({
            ...line,
            points: line.points.map((p) => ({
              x: p.x * scaleX,
              y: p.y * scaleY,
            })),
            brushRadius: line.brushRadius * scaleAvg,
          })),
          edit: null,
        });
      }
    };

    loadSketch = () => {
      const lines = [];
      this.lines = this.props.savedSketch;
      setCurrentSketch(this.lines);
      this.lines.forEach((line) => {
        lines.push(line);
      });
      this.setState({ index: lines.length - 1 });
      this.simulateDrawingLines({ lines, edit: 'saved' });
      this.triggerOnChange();
      this.sketchLoaded = true;
    }

    submitSketch = (grade) => {
      setCurrentSketchTime(this.sketchTimer);
      const svg = serializeSvg(this.lines);
      const png = this.canvas.drawing.toDataURL('image/png');
      this.props.onSubmit(svg, png, grade);
      this.props.onChange(false);
      this.sketchTimer = 0;
    }

    updateHintPeek = (display) => {
      this.props.toggleHintPeekDisplay(display);
    };

    // eslint-disable-next-line no-unused-vars
    saveLine = ({ brushColor, brushRadius, edit, drawingMode = this.drawingMode }) => {
      if (this.points.length < 2) return;

      // Our custom format
      this.lines.push({
        strokeWidth: brushRadius || this.props.brushRadius,
        drawingMode,
        path: [...this.points],
      });

      setCurrentSketch(this.lines);
      if (edit === null) {
        this.state.index += 1;
        this.redoStack = [];
      }

      // Reset points array
      this.points.length = 0;

      const { width } = this.canvas.temp;
      const { height } = this.canvas.temp;

      // Copy the line to the drawing canvas
      this.ctx.drawing.drawImage(this.canvas.temp, 0, 0, width, height);

      // Clear the temporary line-drawing canvas
      this.ctx.temp.clearRect(0, 0, width, height);

      this.triggerOnChange();
    };

    triggerOnChange = () => {
      // eslint-disable-next-line no-unused-expressions
      this.props.onChange && this.props.onChange(this);
    };

    handleDrawStart = (e) => {
      e.preventDefault();

      // Start drawing
      this.isPressing = true;
      this.sketchStartTime = new Date();

      const { x, y } = this.getPointerPos(e);

      if (e.touches && e.touches.length > 0) {
        // on touch, set catenary position to touch pos
        this.lazy.update({ x, y }, { both: true });
      }

      // Ensure the initial down position gets added to our line
      this.handlePointerMove(x, y);
      this.sketchUpdated = true;
    };

    handleDrawMove = (e) => {
      e.preventDefault();

      const { x, y } = this.getPointerPos(e);
      this.handlePointerMove(x, y);
    };

    handleTouchMove = (e) => {
      e.preventDefault();

      let touchTimeout;
      clearTimeout(touchTimeout);
      const { x, y } = this.getPointerPos(e);
      this.handlePointerMove(x, y);
      touchTimeout = setTimeout(() => {
        const { currX, currY } = this.getPointerPos(e);
        this.handlePointerMove(currX, currY);
        if (this.isPressing && this.isDrawing) {
          this.points = simplifyPath(this.points, this.tolerance, false);
          this.isPressing = false;
        }
      }, this.holdTime);
    };

    handleDrawEnd = (e) => {
      e.preventDefault();

      // Draw to this end pos
      this.handleDrawMove(e);

      if (this.isPressing) {
        this.sketchTimer += Math.round((new Date() - this.sketchStartTime) / 1000);
        setCurrentSketchTime(this.sketchTimer);
      }

      // Stop drawing & save the drawn line
      this.isDrawing = false;
      this.isPressing = false;

      this.saveLine({ brushColor: this.brushColor, brushRadius: this.brushRadius, edit: null });
    };

    handlePointerMove = (x, y) => {
      if (this.props.disabled) return;

      this.lazy.update({ x, y });
      const isDisabled = !this.lazy.isEnabled();

      if (
        (this.isPressing && !this.isDrawing)
            || (isDisabled && this.isPressing)
      ) {
        // Start drawing and add point
        this.isDrawing = true;
        this.points.push(this.lazy.brush.toObject());
      }

      if (this.isDrawing) {
        // Add new point
        this.points.push(this.lazy.brush.toObject());

        // Draw current points
        this.drawPoints({
          points: this.points,
          brushColor: this.drawingMode === 'DRAW' ? '#000000' : '#ff0000',
          brushRadius: this.brushRadius || this.props.brushRadius,
        });
      }

      this.mouseHasMoved = true;
    };

    drawImage = () => {
      if (!this.props.imgSrc) return;

      // Load the image
      this.image = new Image();

      // Prevent SecurityError "Tainted canvases may not be exported." #70
      this.image.crossOrigin = 'anonymous';

      // Draw the image once loaded
      this.image.onload = () => { };
      this.image.src = this.props.imgSrc;
    };

    drawPoints = ({ points, brushColor, brushRadius, drawingMode = this.drawingMode }) => {
      this.ctx.temp.lineJoin = 'round';
      this.ctx.temp.lineCap = 'round';
      this.ctx.temp.strokeStyle = brushColor;

      this.ctx.temp.clearRect(
        0,
        0,
        this.ctx.temp.canvas.width,
        this.ctx.temp.canvas.height,
      );
      this.ctx.temp.lineWidth = brushRadius * 1.15;

      let p1 = points[0];
      let p2 = points[1];

      this.ctx.temp.beginPath();

      // Change this.ctx.drawing.globalCompositeOperation based on the drawing mode
      if (drawingMode === 'DRAW') {
        this.ctx.drawing.globalCompositeOperation = 'source-over';
        if (!p2) {
          p2 = p1;
        }

        this.ctx.temp.moveTo(p2.x, p2.y);
        for (let i = 1, len = points.length; i < len; i += 1) {
          // we pick the point between pi+1 & pi+2 as the
          // end point and p1 as our control point
          this.ctx.temp.lineTo(p1.x, p1.y);
          p1 = points[i];
          p2 = points[i + 1];
        }
        // Draw last line as a straight line while
        // we wait for the next point to be able to calculate
        // the bezier control point
        this.ctx.temp.lineTo(p1.x, p1.y);
        this.ctx.temp.stroke();
      } else {
        // LASSO ERASE
        this.ctx.drawing.globalCompositeOperation = 'destination-out';
        if (drawingMode === 'LASSO') {
          for (let i = 1, len = points.length; i < len; i += 1) {
            const midPoint = midPointBtw(p1, p2);
            this.ctx.temp.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            p1 = points[i];
            p2 = points[i + 1];
          }
          this.ctx.temp.arc(p1.x, p1.y, 8, 0, Math.PI * 2, false);
          this.ctx.temp.fillStyle = 'red';
          this.ctx.temp.fill();
        } else {
          // NORMAL ERASE
          this.ctx.drawing.globalCompositeOperation = 'destination-out';
          for (let i = 1, len = points.length; i < len; i += 1) {
            this.ctx.temp.lineTo(p1.x, p1.y);
            this.ctx.temp.stroke();
            p1 = points[i];
            p2 = points[i + 1];
          }
        }
      }
    };

    drawGrid = (ctx) => {
      if (this.props.hideGrid) return;

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      ctx.beginPath();
      ctx.setLineDash([5, 1]);
      ctx.setLineDash([]);
      ctx.strokeStyle = this.props.gridColor;
      ctx.lineWidth = 0.5;

      const gridSize = 25;

      let countX = 0;
      while (countX < ctx.canvas.width) {
        countX += gridSize;
        ctx.moveTo(countX, 0);
        ctx.lineTo(countX, ctx.canvas.height);
      }
      ctx.stroke();

      let countY = 0;
      while (countY < ctx.canvas.height) {
        countY += gridSize;
        ctx.moveTo(0, countY);
        ctx.lineTo(ctx.canvas.width, countY);
      }
      ctx.stroke();
    };

    drawInterface = (ctx, pointer, brush) => {
      if (this.props.hideInterface) return;

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Draw brush preview
      ctx.beginPath();
      ctx.fillStyle = this.props.brushColor;
      ctx.arc(brush.x, brush.y, this.props.brushRadius, 0, Math.PI * 2, true);
      ctx.fill();

      // Draw mouse point (the one directly at the cursor)
      ctx.beginPath();
      ctx.fillStyle = this.props.catenaryColor;
      ctx.arc(pointer.x, pointer.y, 4, 0, Math.PI * 2, true);
      ctx.fill();

      // Draw catenary
      if (this.lazy.isEnabled()) {
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.lineCap = 'round';
        ctx.setLineDash([2, 4]);
        ctx.strokeStyle = this.props.catenaryColor;
        ctx.stroke();
      }

      // Draw brush point (the one in the middle of the brush preview)
      ctx.beginPath();
      ctx.fillStyle = this.props.catenaryColor;
      ctx.arc(brush.x, brush.y, 2, 0, Math.PI * 2, true);
      ctx.fill();
    };

    simulateDrawingLines = ({ lines, edit }) => {
      lines.forEach((line) => {
        // eslint-disable-next-line prefer-const
        if (line.path?.length > 0) {
          const points = line.path;
          const brushColor = line.drawingMode === 'DRAW' ? '#000000' : '#ff0000';
          const brushRadius = line.strokeWidth;
          // Draw the points
          this.drawPoints({
            points,
            brushColor,
            brushRadius,
            drawingMode: line.drawingMode,
          });

          // Save line with the drawn points
          this.points = points;
          this.saveLine({ brushColor, brushRadius, edit, drawingMode: line.drawingMode });
        }
      });
    };

    clearCanvas = () => {
      this.lines = [];
      this.valuesChanged = true;
      this.ctx.drawing.clearRect(
        0,
        0,
        this.canvas.drawing.width,
        this.canvas.drawing.height,
      );
      this.ctx.temp.clearRect(
        0,
        0,
        this.canvas.temp.width,
        this.canvas.temp.height,
      );
    };

    loop = ({ once = false } = {}) => {
      if (this.mouseHasMoved || this.valuesChanged) {
        const pointer = this.lazy.getPointerCoordinates();
        const brush = this.lazy.getBrushCoordinates();

        this.drawInterface(this.ctx.interface, pointer, brush);
        this.mouseHasMoved = false;
        this.valuesChanged = false;
      }

      if (!once) {
        window.requestAnimationFrame(() => {
          this.loop();
        });
      }
    };

    undo = () => {
      if (this.state.index >= 0 && this.lines.length > 0) {
        const last = this.lines[this.lines.length - 1];
        this.redoStack.push(last);
        const lines = this.lines.slice(0, this.lines.length - 1);
        this.setState({ index: lines.length - 1 });
        this.clearCanvas();
        this.simulateDrawingLines({ lines, edit: 'undo' });
        this.triggerOnChange();
      } else if (this.linesFromClear.length > 0) {
        this.lines = this.linesFromClear;
        const { lines } = this;
        this.linesFromClear = [];
        this.setState({ index: lines.length - 1 });
        this.redoStack.push('CLEAR');
        this.clearCanvas();
        this.simulateDrawingLines({ lines, edit: 'undo' });
        this.triggerOnChange();
      }
      setCurrentSketch(this.lines);
    };

    redo = () => {
      if (this.redoStack.length > 0) {
        const next = this.redoStack.pop();
        if (next === 'CLEAR') this.clear();
        else {
          this.lines.push(next);
          const lines = [...this.lines];
          this.setState({ index: lines.length - 1 });
          this.clearCanvas();
          this.simulateDrawingLines({ lines, edit: 'redo' });
          this.triggerOnChange();
        }
      }
      setCurrentSketch(this.lines);
    }

    clear = () => {
      this.linesFromClear = this.lines;
      this.setState({ index: 0 });
      this.lines = [];
      this.valuesChanged = true;
      this.ctx.drawing.clearRect(
        0,
        0,
        this.canvas.drawing.width,
        this.canvas.drawing.height,
      );
      this.ctx.temp.clearRect(
        0,
        0,
        this.canvas.temp.width,
        this.canvas.temp.height,
      );
    };

    getPointerPos = (e) => {
      const rect = this.canvas.interface.getBoundingClientRect();

      // use cursor pos as default
      let { clientX } = e;
      let { clientY } = e;

      // use first touch if available
      if (e.changedTouches && e.changedTouches.length > 0) {
        clientX = e.changedTouches[0].clientX;
        clientY = e.changedTouches[0].clientY;
      }

      // return mouse/touch position inside canvas
      return {
        x: clientX - rect.left,
        y: clientY - rect.top,
      };
    };

    setDrawingMode = (mode) => {
      this.drawingMode = mode;
    }

    changeEraseSize = (size = this.props.brushRadius) => {
      this.brushRadius = size;
    }

    onUpdateSketchTimer = () => {
      this.props.onUpdateSketchTimer(this.sketchTimer);
    }

    renderStars = () => {
      let stars = 0;
      if (this.props.didPeek) stars = 1;
      else if (this.props.didHint) stars = 2;
      if (!this.props.didPeek && !this.props.didHint) stars = 3;
      const numStars = Array(stars).fill(0);
      return (
        <div
          style={{
            alignSelf: 'center',
            float: 'right',
            top: '90%',
            position: 'relative',
            zIndex: 20,
          }}
        >
          { numStars.map(() => (
            <StarIcon
              style={
              {
                color: '#f7b727',
                fontSize: '60px',
              }
              }
            />
          ))}
        </div>
      );
    };

    render() {
      return (
        <div
          id="canvas-div"
          className={this.props.className}
          style={{
            display: 'block',
            background: this.props.backgroundColor,
            touchAction: 'none',
            width: this.props.canvasWidth,
            height: this.props.canvasHeight,
            ...this.props.style,
          }}
          ref={(container) => {
            if (container) {
              this.canvasContainer = container;
            }
          }}
        >
          {!this.props.hintPeekData.displayed && (
          <CanvasToolbar
            undo={this.undo}
            redo={this.redo}
            clear={this.clear}
            setDrawingMode={this.setDrawingMode}
            changeEraseSize={this.changeEraseSize}
            submit={this.submitSketch}
            isCorrect={this.props.isCorrect}
            hintPeekData={this.props.hintPeekData}
            toggleHintPeekDisplay={this.updateHintPeek}
            assignmentId={this.props.lessonAssignmentId}
            onUpdateSketchTimer={this.onUpdateSketchTimer}
            sketchTimer={this.sketchTimer}
            brushRadius={this.props.brushRadius}
          />
          )}
          {canvasTypes.map(({ name, zIndex }) => {
            const isInterface = name === 'interface';
            // when hinting/peeking the drawing canvas is hidden
            const isHidden = name === 'drawing' && this.props.shouldHideSketch;
            return (
              <canvas
                key={name}
                ref={(canvas) => {
                  if (canvas) {
                    this.canvas[name] = canvas;
                    this.ctx[name] = canvas.getContext('2d');
                  }
                }}
                width={this.props.canvasWidth}
                height={this.props.canvasHeight}
                style={{
                  ...canvasStyle,
                  zIndex,
                  display: isHidden ? 'none' : 'block',
                }}
                onMouseDown={isInterface ? this.handleDrawStart : undefined}
                onMouseMove={isInterface ? this.handleDrawMove : undefined}
                onMouseUp={isInterface ? this.handleDrawEnd : undefined}
                onMouseOut={isInterface ? this.handleDrawEnd : undefined}
                onTouchStart={isInterface ? this.handleDrawStart : undefined}
                onTouchMove={isInterface ? this.handleTouchMove : undefined}
                onTouchEnd={isInterface ? this.handleDrawEnd : undefined}
                onTouchCancel={isInterface ? this.handleDrawEnd : undefined}
              />
            );
          })}
          {!this.props.hintPeekData.displayed && (
            <>
              {this.props.isCorrect && (
                <>
                  {this.renderStars()}
                </>
              )}
            </>
          )}
        </div>
      );
    }
}

export default Canvas;
