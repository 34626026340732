import React, { useState, useEffect } from 'react';
import apiWrapper from '../../api';
import MainLayout from '../../components/main-layout';
import {
  Form,
  Button,
  Table,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import Paginate from '../../components/paginate';
import './styles.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchRequest, setSearchRequest] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
      getAllUsers();
  }, []);
    
  const getAllUsers = () => {
    apiWrapper
      .getUsers(1)
      .then((res) => {
        setUsers(res.data.records);
        setTotalPages(res.data.pagination.total_page);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  const changePageFunction = (number) => {
      setActivePage(number);
      apiWrapper.getUsers(number).then((res) => {
        setUsers(res.data.records);
      });
  };

  const apiRequest = (query) => {
    if (query === "") {
      getAllUsers();
    } else {
      apiWrapper.searchUser(query).then((res) => {
        setUsers(res.data.records);
        setTotalPages(res.data.pagination.total_page);
      });
    }
  };

  const onChangeSearchRequest = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setSearchRequest(e.target.value);
    const query = e.target.value;
    setTypingTimeout(
      setTimeout(() => {
        apiRequest(query);
      }, 1000),
    );
  };

  const onAccountLevelSelect = (e) => {
    const id = e.target.id.split('_')[1];
    let updatedUsers = users;
    updatedUsers[id].account_level = e.target.value;
    setUsers([...updatedUsers]);
  }

  const saveAccountLevelChange = (e) => {
    const index = e.target.id;
    const userId = users[index].id;
    const newAccountLevel = users[index].account_level;
    if (newAccountLevel === 'no access') {
      apiWrapper.deactivateUser(userId).then((res) => {
        console.log(res);
      }).catch((error) => { console.log(error); });
    } else {
      apiWrapper.changeAccountLevel(userId, newAccountLevel).then((res) => {
        console.log(res);
      }).catch((error) => { console.log(error); });
    }
  }

    const renderUsers = () => users.map((user, index) => (
      <tr key={user.id}>
        <Form.Group className="mt-3" controlId={"formAccountLevel_" + index}>
          <Form.Control as="select" name="account_level" value={user.account_level} onChange={onAccountLevelSelect} required>
            <option disabled value={0}>Select option</option>
            <option key={1} value="student">student</option>
            <option key={1} value="TA">TA</option>
            <option key={2} value="teacher">teacher</option>
            <option key={3} value="admin">admin</option>
            <option key={4} value="no access">no access</option>
          </Form.Control>
        </Form.Group>
        <td>{user.last_name}</td>
        <td>{user.first_name}</td>
        <td>
          {user.email}
          <Button 
            id={index}
            variant="success" 
            className="align-self-center button-style save-button" 
            onClick={saveAccountLevelChange}
            >
              Save
          </Button>
        </td>
      </tr>
    ));
    return (
      <MainLayout>
        <div className="d-flex justify-content-between mt-4">
          <h1> User Management </h1>
        </div>
        <InputGroup className="mt-4">
          <FormControl
            placeholder="Find a user"
            aria-label="Find a user"
            value={searchRequest}
            onChange={onChangeSearchRequest}
          />
          <InputGroup.Append>
              <Button variant="outline-secondary">Search</Button>
          </InputGroup.Append>
        </InputGroup>
        <Table responsive className="mt-4">
          <thead>
          <tr>
              <th>Role</th>
              <th>Last name</th>
              <th>First name</th>
              <th>Email</th>
          </tr>
          </thead>
          <tbody>{renderUsers()}</tbody>
        </Table>
        <Paginate totalPages={totalPages} changePageFunction={changePageFunction} currPage={activePage}/>
      </MainLayout>
    );
};

export default UserManagement;