/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import {
  Row, Col, Button, Form, Alert, Spinner,
} from 'react-bootstrap';
import apiWrapper from '../../api';
import MainLayout from '../../components/main-layout';
import AssignmentSummary from './assignment-summary';
import Assignments from './assignments';
import NameForm from './name-form';
import { authContext } from '../../contexts/auth-context';
import './styles.css';

const AssignmentSetForm = ({ history, location }) => {
  const { auth } = useContext(authContext);

  const [syllabus, setSyllabus] = useState([]);
  const [currentLesson, setCurrentLesson] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [assignmentSetName, setAssignmentSetName] = useState();
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCoursesIds, setSelectedCoursesIds] = useState([]);
  const [selectedCoursesNames, setSelectedCoursesNames] = useState([]);
  const [assignmentSetId, setAssignmentSetId] = useState([]);
  // currentPage starts with 1, currentPage where we start to show possible assignments starts with 2. Syllabus index starts with 0.
  const neededSylabusIndex = currentPage - 2;
  const assignPage = 12;
  const [coursesAssignedToCurrentSet, setCoursesAssignedToCurrentSet] = useState([]);
  const [error, setError] = useState();
  const [assignmentSummaryLoaded, setAssignmentSummaryLoaded] = useState(false);
  const [byNavigate, setByNavigate] = useState(false);

  useEffect(() => {
    apiWrapper.getSyllabus().then((res) => {
      const lessons = res.data;
      lessons.sort((a, b) => a.display_order - b.display_order);
      setSyllabus(lessons);
    });
    apiWrapper.getCourses().then((res) => {
      setCourses(res.data);
    });
    // checking if we accessed this component clicking on edit button
    const fromEdit = location.state;
    if (fromEdit && fromEdit.chosenAssignmentSet) {
      const assignmentSetExist = fromEdit.chosenAssignmentSet;
      setAssignmentSetName(assignmentSetExist.name);
      setAssignmentSetId(assignmentSetExist.id);
    }
    if (location.state && location.state.page === assignPage) buildAssignmentSummary(fromEdit);
  }, [location.state]);

  useEffect(() => {
    if (currentPage === assignPage) getCoursesAssignedToCurrent();
    if (currentPage > 1 && currentPage <= syllabus.length + 1) {
      window.scrollTo(0, 0);
      const lessonId = syllabus[neededSylabusIndex].id;
      apiWrapper.getAssignments(lessonId).then((res) => {
        setAssignments(res.data);
        // checking if we accessed this component clicking on edit button
        const fromEdit = location.state;
        if (fromEdit && fromEdit.chosenAssignmentSet) {
          const [selectedIds, selectedAverageTime, alreadySelectedAssignments] = getSelectedLessons(fromEdit.chosenAssignmentSet, neededSylabusIndex, res);
          if (!alreadySelectedAssignments) {
            setSelectedAssignments([...selectedAssignments, {
              lesson: syllabus[neededSylabusIndex],
              ids: selectedIds,
              averageTime: selectedAverageTime,
            }]);
          }
        }
      });
    }
  }, [currentPage, location.state, neededSylabusIndex, selectedAssignments, syllabus]);

  const buildAssignmentSummary = (fromEdit) => {
    setAssignmentSummaryLoaded(true);
    setCurrentPage(assignPage);
    getCoursesAssignedToCurrent();
    apiWrapper.getSyllabus().then(async (res) => {
      const lessons = res.data;
      lessons.sort((a, b) => a.display_order - b.display_order);
      let testAssignments = selectedAssignments;
      for (let page = 0; page < 10; page++) {
        const lessonId = lessons[page].id;
        const response = await apiWrapper.getAssignments(lessonId);
        // checking if we accessed this component clicking on edit button
        if (fromEdit && fromEdit.chosenAssignmentSet) {
          const [selectedIds, selectedAverageTime, alreadySelectedAssignments] = getSelectedLessons(fromEdit.chosenAssignmentSet, page, response);
          if (!alreadySelectedAssignments) {
            setSelectedAssignments([...testAssignments, {
              lesson: lessons[page],
              ids: selectedIds,
              averageTime: selectedAverageTime,
            }]);
            testAssignments = [...testAssignments, {
              lesson: lessons[page],
              ids: selectedIds,
              averageTime: selectedAverageTime,
            }];
          }
        }
      }
      setAssignmentSummaryLoaded(false);
    });
  };

  const getSelectedLessons = (chosenAssignmentSet, index, response) => {
    const assignmentSetExist = chosenAssignmentSet;
    // in assignments that we recieved from API, we are looking for assignments that have the same ids as assignments we recieved from location.state
    const selected = response.data.filter((element) => assignmentSetExist.assignments.some((item) => item.id === element.id));
    // transforming them in selectedAssignments form: {lesson: {}, ids: [], averageTime: number};
    const selectedIds = selected.map((item) => item.id);
    const selectedAverageTime = selected.reduce((acc, current) => acc + current.average_time, 0);
    // as this useEffect fires everytime currentPage changes, we should make sure that slectedAssignments that was set previously won't be modified
    const alreadySelectedAssignments = selectedAssignments.find((assignment) => assignment.lesson.id === syllabus[index].id);
    return [selectedIds, selectedAverageTime, alreadySelectedAssignments];
  };

  const getCoursesAssignedToCurrent = () => {
    const courseIds = selectedCoursesIds;
    const courseNames = selectedCoursesNames;
    const currentAssignmentSetCourses = coursesAssignedToCurrentSet;
    courses.forEach((course) => {
      const alreadyExists = selectedCoursesIds.find((id) => id === course.id);
      const addCurrentSetToCourse = coursesAssignedToCurrentSet.find((c) => c === course);
      if (course.assignment_set_id === assignmentSetId && !alreadyExists) {
        courseIds.push(course.id);
        courseNames.push(course.name);
        if (!addCurrentSetToCourse) currentAssignmentSetCourses.push(course);
      }
    });
    setSelectedCoursesIds([...courseIds]);
    setSelectedCoursesNames([...courseNames]);
    setCoursesAssignedToCurrentSet([...currentAssignmentSetCourses]);
  };

  const nameFormSubmit = (name) => {
    setAssignmentSetName(name);
    setCurrentPage(currentPage + 1);
  };

  const addAssignments = (ids, averageTime, lesson) => {
    const newAssignments = selectedAssignments.filter((assignment) => assignment.lesson.id !== lesson.id);
    setSelectedAssignments([...newAssignments, {
      lesson,
      ids,
      averageTime,
    }]);
  };

  const onNext = async (ids, averageTime, lesson, justSave) => {
    if (!justSave) {
      setCurrentLesson(syllabus[neededSylabusIndex + 1]);
      setCurrentPage(currentPage + 1);
    }
    await addAssignmentsToSummary();
    addAssignments(ids, averageTime, lesson);
  };

  const onBack = async (ids, averageTime, lesson) => {
    setCurrentLesson(syllabus[neededSylabusIndex - 1]);
    setCurrentPage(currentPage - 1);
    await addAssignmentsToSummary();
    if (currentPage < assignPage) addAssignments(ids, averageTime, lesson);
  };

  const onNavigate = (e) => {
    const page = parseInt(e.target.innerHTML);
    setCurrentLesson(syllabus[page - 2]);
    setCurrentPage(page);
    setByNavigate(true);
    addAssignmentsToSummary();
  };

  const addByNavigate = (ids, averageTime, lesson) => {
    addAssignments(ids, averageTime, lesson);
    addAssignmentsToSummary();
    setByNavigate(false);
  };

  const addAssignmentsToSummary = async () => {
    setAssignmentSummaryLoaded(true);
    let index = 0;
    let testAssignments = selectedAssignments;
    for (const lesson of syllabus) {
      const lessonId = lesson.id;
      const response = await apiWrapper.getAssignments(lessonId);
      if (location.state && location.state.chosenAssignmentSet) {
        const [selectedIds, selectedAverageTime, alreadySelectedAssignments] = getSelectedLessons(location.state.chosenAssignmentSet, index, response);
        if (!alreadySelectedAssignments) {
          setSelectedAssignments([...testAssignments, {
            lesson: syllabus[index],
            ids: selectedIds,
            averageTime: selectedAverageTime,
          }]);
          testAssignments = [...testAssignments, {
            lesson: syllabus[index],
            ids: selectedIds,
            averageTime: selectedAverageTime,
          }];
        }
      } else {
        const foundAssignment = selectedAssignments.find((assignment) => assignment.lesson.id === lesson.id);
        if (!foundAssignment) {
          setSelectedAssignments([...testAssignments, {
            lesson: syllabus[index],
            ids: [],
            averageTime: 0,
          }]);
          testAssignments = [...testAssignments, {
            lesson: syllabus[index],
            ids: [],
            averageTime: 0,
          }];
        }
      }
      index++;
    }
    setAssignmentSummaryLoaded(false);
  };

  const assignCourses = async (assignmentSet) => {
    for (const course of courses) {
      if (selectedCoursesIds.indexOf(course.id) >= 0) {
        course.assignment_set = assignmentSet;
        course.assignment_set_id = assignmentSet.id;
        await apiWrapper.updateCourseAssignmentSet(course.id, assignmentSet.id);
      } else if (selectedCoursesIds.indexOf(course.id) < 0 && course.assignment_set_id === assignmentSet.id) {
        await apiWrapper.updateCourseAssignmentSet(course.id, 0);
      }
    }
  };

  const onConfirm = () => {
    const assignmentsId = selectedAssignments.map((assignment) => assignment.ids).flat();
    const fromEdit = location.state;
    if (fromEdit && fromEdit.chosenAssignmentSet) {
      const assignmentSetExist = fromEdit.chosenAssignmentSet;
      if (assignmentSetExist.preset && auth.user.account_level !== 'admin') {
        assignCourses(fromEdit.chosenAssignmentSet).then(() => {
          history.replace('/assignment-sets');
        }).catch((err) => {
          setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
        });
        return;
      }
      apiWrapper.editAssignmentSet(assignmentSetExist.id, assignmentSetName, assignmentsId).then(() => {
        assignCourses(fromEdit.chosenAssignmentSet).then(() => {
          history.replace('/assignment-sets');
        }).catch((err) => {
          setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
        });
      }).catch((err) => {
        setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
      });
    } else {
      apiWrapper.createAssignmentSet(assignmentSetName, assignmentsId).then((res) => {
        assignCourses(res.data).then(() => {
          history.replace('/assignment-sets');
        }).catch((err) => {
          setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
        });
      }).catch((err) => {
        setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
      });
    }
  };

  const renderCoursesAssignedTo = () => selectedCoursesNames.map((courseName, index) => {
    const course = courses.find((current) => current.id === selectedCoursesIds[index]);
    if (course && course.assignment_set_id === assignmentSetId) {
      return (
        <li key={course.id}>
          <div className="assigned-to">
            <div className="assigned-courses"><b>{courseName}</b></div>
            <div className="assign-warning">
              <p>
                Important:
                {courseName}
                is already assigned to
                {course.assignment_set.name}
                assignment set.
              </p>
            </div>
          </div>
        </li>
      );
    } if (course && course.assignment_set) {
      return (
        <li key={course.id}>
          <div className="assigned-to">
            <div className="assigned-courses"><b>{courseName}</b></div>
            <div className="assign-warning">
              <p>
                Important:
                {courseName}
                {' '}
                is currently assigned the
                {course.assignment_set.name}
                {' '}
                assignment set. Confirming will assign it to
                {assignmentSetName}
                {' '}
                and overwrite the previous assignment set.
              </p>
            </div>
          </div>
        </li>
      );
    }
    return (
      <li>
        <b>{courseName}</b>
      </li>
    );
  });

  const renderNavigation = () => {
    const renderCheckpoint = (name, index, key) => {
      const colorCheckpointStroke = (currentPage === index || currentPage > index) ? 'rgb(161, 206, 117)' : 'rgb(194, 196, 192)';
      const colorText = (currentPage === index || currentPage > index) ? 'rgb(161, 206, 117)' : 'grey';
      const checkpointBackground = currentPage > index ? 'rgb(161, 206, 117)' : 'white';
      const checkpointColorText = currentPage === index ? 'rgb(161, 206, 117)' : (currentPage > index ? 'white' : 'grey');

      return (
        <div key={key} style={{ position: 'relative' }}>
          <div className="d-flex">
            {index !== 1 && (
              <div className="stroke-container">
                <div className="stroke" style={{ backgroundColor: colorCheckpointStroke }} />
              </div>
            )}
            <div className="circle">
              <Button onClick={onNavigate} style={{ border: `2px solid ${colorCheckpointStroke}`, backgroundColor: checkpointBackground, color: checkpointColorText }} className="navigation" variant="success">
                {index}
              </Button>
              <p className="mt-2 checkpoint-title assignments-font-size" style={{ position: 'absolute', top: '100%', color: colorText }}>
                {index > 2 && index < 12 ? `L${index - 2} ${name}` : name}
              </p>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="d-flex">
        {renderCheckpoint('Name', 1)}
        {syllabus.map((lesson, index) => renderCheckpoint(lesson.short_name, index + 2, lesson.id))}
        {renderCheckpoint('Assign', assignPage)}
        {renderCheckpoint('Confirm', assignPage + 1)}
      </div>
    );
  };

  const onCourseToggle = (chosenCourse) => {
    const alreadyExists = selectedCoursesIds.find((id) => id === chosenCourse.id);
    if (alreadyExists) {
      const newCoursesIds = selectedCoursesIds.filter((id) => id !== chosenCourse.id);
      const newCoursesNames = selectedCoursesNames.filter((name) => name !== chosenCourse.name);
      setSelectedCoursesIds(newCoursesIds);
      setSelectedCoursesNames(newCoursesNames);
    } else {
      setSelectedCoursesIds([...selectedCoursesIds, chosenCourse.id]);
      setSelectedCoursesNames([...selectedCoursesNames, chosenCourse.name]);
    }
  };

  const onAssignToConfirm = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <MainLayout history={history}>
      <Row className="mt-4">
        <Col>
          <h1>Create a Custom Assignment Set</h1>
          <p className="assignments-font-size">Specify which assignments students are required to complete</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {syllabus && (
            renderNavigation()
          )}
        </Col>
      </Row>
      {currentPage === 1 && (
        <NameForm onFormSubmitted={nameFormSubmit} defaultName={assignmentSetName} preset={location.state && location.state.chosenAssignmentSet ? location.state.chosenAssignmentSet.preset : false} />
      )}
      {assignments && currentPage > 1 && currentPage <= syllabus.length + 1 && (
        <Row className="mt-5">
          <Col sm={12} lg={7}>
            <Assignments
              assignments={assignments}
              lesson={currentLesson || syllabus[neededSylabusIndex]}
              index={neededSylabusIndex}
              onNext={onNext}
              assignmentsSelected={selectedAssignments}
              onBack={onBack}
              preset={location.state && location.state.chosenAssignmentSet ? location.state.chosenAssignmentSet.preset : false}
              byNavigate={byNavigate ? addByNavigate : null}
            />
          </Col>
          <Col sm={12} lg={5}>
            {selectedAssignments.length > 0 && (
              <>
                {assignmentSummaryLoaded ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    <AssignmentSummary
                      assignmentInfo={selectedAssignments.sort((a, b) => a.lesson.display_order - b.lesson.display_order)}
                    />
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      )}
      {currentPage === syllabus.length + 2 && (
        <Row className="mt-5 mb-5">
          <Col sm={12} lg={6}>
            <b>{`${currentPage}. Select the course(s) you would like to assign this Custom Assignment Set to`}</b>
            <Form className="mt-2">
              {courses.map((course) => {
                const isChosen = selectedCoursesIds.some((id) => id === course.id);
                return (
                  <Form.Group key={course.id} className="d-flex justify-content-between">
                    <Form.Check
                      onChange={() => { onCourseToggle(course); }}
                      checked={isChosen}
                      type="checkbox"
                      className="text-capitalize"
                      label={course.name}
                    />
                  </Form.Group>
                );
              })}
              <div className="mt-5 d-flex justify-content-between mb-5">
                <Button className="button-style" variant="success" onClick={onBack}>
                  Back
                </Button>
                <Button className="button-style" variant="success" onClick={onAssignToConfirm}>
                  Save &amp; Next
                </Button>
              </div>
            </Form>
          </Col>
          <Col sm={12} lg={6}>
            {selectedAssignments.length > 0 && (
              <>
                {assignmentSummaryLoaded ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    <AssignmentSummary
                      assignmentInfo={selectedAssignments.sort((a, b) => a.lesson.display_order - b.lesson.display_order)}
                    />
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      )}
      {currentPage > syllabus.length + 2 && (
        <>
          <Row className="mt-5 mb-5">
            <Col sm={12} lg={6}>
              <b>{`${currentPage}. Confirmation`}</b>
              <div className="d-flex mt-4">
                <b>Name:</b>
                <p className="ml-3">{assignmentSetName}</p>
              </div>
              {selectedAssignments.length > 0 && (
                <AssignmentSummary
                  assignmentInfo={selectedAssignments.sort((a, b) => a.lesson.display_order - b.lesson.display_order)}
                />
              )}
            </Col>
          </Row>
          {error && (
            <Alert className="text-center mt-3 p-1" variant="danger">
              <small>{`${error}.`}</small>
            </Alert>
          )}
          <Row>
            <div className="mt-4">
              <b>Assigned To:</b>
              <ul>
                {renderCoursesAssignedTo()}
              </ul>
            </div>
          </Row>
          <Button className="button-style" variant="success" onClick={onBack}>
            Back
          </Button>
          <Button className="button-style confirm-button" variant="success" onClick={onConfirm}>
            Confirm and Assign
          </Button>
        </>
      )}
    </MainLayout>
  );
};

export default AssignmentSetForm;
