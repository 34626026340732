import React, { useState, useEffect } from 'react';
import './styles.css';
import { Button, Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import FullLogo from '../../resources/images/full_logo.svg';
import LogInInput from '../form-input';
import apiWrapper from '../../api';

const ResetPassword = ({ history, message, enrollment_type, userInfo }) => {
  const [passwordConfirmation, setPasswordConfimation] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { id, key } = useParams();
  const [usernamePasswordChanged, setUsernamePasswordChange] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');
  useEffect(() => {
    setDisplayMessage(message);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setError('Passwords do not match');
      return;
    }
    
    if (enrollment_type === "username") {
      apiWrapper.resetPasswordByUsername(userInfo.id, password).then(() => {
        setDisplayMessage(`You successfully changed the password for ${userInfo.username}.`);
        setUsernamePasswordChange(true);
      }).catch((err) => {
        setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
      });
    } else {
      apiWrapper.resetPassword(id, key, password).then(() => {
        history.replace('/sign-in', { flash: 'You successfully created your account. Please log-in.' });
      }).catch((err) => {
        setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
      });
    }
  };

  const onValueChange = (value, type) => {
    switch (type) {
      case 'password':
        setPassword(value);
        break;
      case 'password_confirm':
        setPasswordConfimation(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="main-container">
      <div className="form-width">
        <img src={FullLogo} className="w-100" alt="Logo" />
        {error && (
          <Alert className="text-center mt-3 p-1" variant="danger">
            <small>{`${error}.`}</small>
          </Alert>
        )}
        {!usernamePasswordChanged ? (
          <><Form onSubmit={onSubmit} className="w-100 mt-3">
            <Alert className="text-center mt-3 p-1" variant="info">
              {displayMessage}
            </Alert>
            <LogInInput
              title="Password"
              type="password"
              value={password}
              placeholder="Password"
              onValueChange={(value) => { onValueChange(value, 'password'); }}
            />
            <LogInInput
              title="Password Confirmation"
              type="password"
              placeholder="Password Confirmation"
              value={passwordConfirmation}
              onValueChange={(value) => { onValueChange(value, 'password_confirm'); }}
            />
            <Button variant="success" className="w-100 mt-3 button-style" type="submit">
              Submit
            </Button>
          </Form></>
          ) :
          (
          <><Alert className="text-center mt-3 p-1" variant="info">
            {displayMessage}
          </Alert></>
        )}
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  message: PropTypes.string.isRequired,
};

export default ResetPassword;
