import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, InputGroup, Row, Col, FormControl, Alert,
} from 'react-bootstrap';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';
import { authContext } from '../../contexts/auth-context';

const CourseSelection = ({ location, history }) => {
  const { auth, setAuthData } = useContext(authContext);
  const [code, setCode] = useState();
  const [message, setMessage] = useState();
  const [courseInformation, setCourseInformation] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    apiWrapper.getUserEnrollments(auth.user.id).then((res) => {
      if (res.data && res.data.length
        && (location && (!location.state || !location.state.addNewEnrollment))) {
        history.push('/spatial-vis-web', { userData: auth.user });
      }
    });
  }, []);

  const onValueChange = (e) => {
    setCode(e.target.value);
  };

  const onCodeSubmit = () => {
    setError();
    setCourseInformation();
    apiWrapper.getPrice(code).then((res) => {
      setCourseInformation({
        code: res.data.code,
        is_paid: res.data.is_paid,
        price: res.data.price,
        name: res.data.name,
      });
    }).catch((err) => {
      setError(err.response.data.error.message);
    });
  };

  const onEnrollment = () => {
    if (courseInformation.is_paid || auth.user.has_paid) {
      apiWrapper.enroll(courseInformation.code).then(() => {
        setAuthData({ ...auth });
        history.push('/spatial-vis-web', { userData: auth.user });
      }).catch((err) => {
        setError(err.response.data.error.message);
      });
      return;
    }

    history.push(`/payment/${code}`);
  };

  return (
    <MainLayout>
      <Row className="mt-4">
        <Col md={4} />
        <Col xs={12} md={4} className="text-center">
          <h3>Enrollment</h3>
          {
            message && (
              <Alert className="text-center mt-3 p-1" variant="success">
                <small>{`${message}`}</small>
              </Alert>
            )
          }
          <p>Enter your invite code</p>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Enter your code"
              aria-label="code"
              onChange={onValueChange}
            />
            <InputGroup.Append>
              <Button variant="primary" onClick={onCodeSubmit}>Submit</Button>
            </InputGroup.Append>
          </InputGroup>
          {error && (
            <Alert variant="warning" className="p-1">
              <small>
                {error}
              </small>
            </Alert>
          )}
          {courseInformation !== undefined && (
            <>
              <div className="d-flex justify-content-between">
                <div>Course Name</div>
                <div className="font-weight-bold">{courseInformation.name}</div>
              </div>
              {!auth.user.has_paid && (
                <div className="d-flex justify-content-between mb-3">
                  <div>Price</div>
                  <div className="font-weight-bold">{`$ ${courseInformation.price}`}</div>
                </div>
              )}
            </>
          )}
          <Button variant="success w-100" disabled={courseInformation === undefined} onClick={onEnrollment}>
            {auth.user.has_paid || (courseInformation && courseInformation.is_paid) ? 'Enroll' : 'Checkout'}
          </Button>
        </Col>
        <Col md={4} />
      </Row>
    </MainLayout>

  );
};

CourseSelection.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CourseSelection;
