import React from 'react';
import PropTypes from 'prop-types';
import apiWrapper from '../../api';
import SchoolLayout from '../../components/school-layout';


const NewSchool = ({ history }) => (
  <SchoolLayout title="Create a new school" history={history} submitFunction={apiWrapper.addSchool} />
);

NewSchool.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default NewSchool;
