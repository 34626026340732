import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

const ViewTableLayout = ({ data, title }) => {
  const renderData = () => data.map((record) => (
    <tr key={record.id}>
      <td>{record.first_name}</td>
      <td>{record.last_name}</td>
      <td>{record.email}</td>
    </tr>
  ));

  return (
    <>
      <h3 className="mt-4">{title}</h3>
      {data.length !== 0 ? (
        <Table responsive bordered className="mt-1">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {renderData()}
          </tbody>
        </Table>
      ) : (
        <div>There are no users in this category.</div>
      )}
    </>
  );
};

ViewTableLayout.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
};

export default ViewTableLayout;
