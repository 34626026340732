import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setAxiosAuthorizationHeader } from '../utils/rest-client';

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, data: null });

  useEffect(() => {
    const newAuthData = JSON.parse(window.localStorage.getItem('authData'));
    setAuth({ loading: false, data: newAuthData });
    if (newAuthData) {
      setAxiosAuthorizationHeader(`Bearer ${newAuthData.token}`);
    }
  }, []);


  const setAuthData = (authData) => {
    setAuth({ data: authData });
    window.localStorage.setItem('authData', JSON.stringify(authData || null));
    setAxiosAuthorizationHeader(authData ? `Bearer ${authData.token}` : '');
  };

  return (
    <authContext.Provider value={{ auth: auth.data, setAuthData, loading: auth.loading }}>
      {children}
    </authContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default AuthProvider;
