/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authContext } from '../../contexts/auth-context';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth, loading } = useContext(authContext);

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => <div />}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        auth ? <Component {...props} /> : <Redirect to="/sign-in" />
      )}
    />

  );
};


PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
