/* eslint-disable radix */
/* eslint-disable import/prefer-default-export */
const getPath = (pathDefinition) => {
  const pattern = /[ML](-?\d+(?:\.d+)?) (-?\d+(\.d+)?)/g;
  const matches = [...pathDefinition.matchAll(pattern)];
  const points = [];
  matches.forEach((pointMatchResult) => {
    const [, x, y] = pointMatchResult;
    points.push({ x: parseInt(x), y: parseInt(y) });
  });
  return points;
};

const getPathDefinition = (match) => {
  const [, definition, strokeWidth] = match;
  if (!strokeWidth || !definition) {
    return null;
  }
  return { strokeWidth, path: getPath(definition) };
};

const getMaskDefinitions = (masks) => {
  const maskDefinitions = new Map();
  const pattern = /<mask id="(\d+)">\s*<rect.*\/>\s*((?:(?:<path.*\/>)\s*)*)<\/mask>/g;
  const matches = [...masks.matchAll(pattern)];
  matches.forEach((match) => {
    const [, maskID, paths] = match;
    if (!maskID || !paths) {
      return maskDefinitions;
    }

    const pathsPattern = /<path d="((?:[ML]-?\d+(?:\.d+)? -?\d+(?:\.d+)?\s*)*)" stroke-width="((?:\d+(?:\.d+)?))".*\/>/g;
    const pathMatches = [...paths.matchAll(pathsPattern)];
    const pathsInMask = [];

    pathMatches.forEach((result) => {
      pathsInMask.push(getPathDefinition(result));
    });

    maskDefinitions.set(maskID, pathsInMask);
  });
  return maskDefinitions;
};

const parseMove = (maskDefinitions, remainingText) => {
  const pathPattern = /^\s*<path d="((?:[ML]-?.+(?:\.d+)? -?.+(?:\.d+)?\s*)*)" stroke-width="(\d+(\.d+)?)".*?\/>/;
  const moves = [];

  let match = remainingText.match(pathPattern);
  if (match) {
    const definition = getPathDefinition(match);
    return {
      newRemainingText: remainingText.replace(pathPattern, ''),
      moves: [{
        path: definition?.path,
        strokeWidth: definition?.strokeWidth,
        drawingMode: 'DRAW',
      }],
    };
  }

  const groupPattern = /^\s*((?:<g mask="url\(#((?:\d+))\)">))(.*)((?:<\/g>))/ms;
  match = remainingText.match(groupPattern);
  if (match) {
    const [,, groupId] = match;

    maskDefinitions.get(groupId).reverse().forEach((path) => {
      moves.push({
        drawingMode: 'ERASE',
        strokeWidth: path.strokeWidth,
        path: path.path,
      });
    });

    return {
      newRemainingText: remainingText.replace(groupPattern, '$3'),
      moves,
    };
  }

  return { newRemainingText: '', moves: [] };
};

const deserializeSvg = (svg) => {
  const lines = [];
  let remainingText = '';
  const pattern = /<defs>\s*((?:<mask id="\d+">(?:\s*<.*\/>)*\s*<\/mask>\s*)*)<\/defs>((?:\s*(?:.*))*)<\/svg>/;
  const [, masks, groups] = svg.match(pattern);
  const maskDefinitions = getMaskDefinitions(masks);
  remainingText = groups;
  while (remainingText !== '') {
    const { newRemainingText, moves } = parseMove(maskDefinitions, remainingText);
    lines.push(...moves);
    remainingText = newRemainingText;
  }
  return lines.reverse();
};

export { deserializeSvg };
