import React, { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Paginate from '../../components/paginate';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';
import ViewTableLayout from '../../components/course-layout/view-table-layout';

const ViewCourse = ({ history }) => {
  const { id } = useParams();

  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    apiWrapper.getCourseTeachers(id).then((res) => {
      setTeachers(res.data.teachers);
    });
    apiWrapper.getCourseStudents(id, 1).then((res) => {
      setStudents(res.data.records);
      setTotalPages(res.data.pagination.total_page);
    });
  }, [id]);

  const changePageFunction = (number) => {
    apiWrapper.getCourseStudents(id, number)
      .then((res) => {
        setStudents(res.data.records);
      });
  };

  return (
    <MainLayout history={history}>
      <h1 className="mt-4">
        Course Details
      </h1>
      <ViewTableLayout data={teachers} title="Teachers" />
      <Button
        style={{ float: 'right' }}
        onClick={() => {
          history.push('stars');
        }}
        variant="success"
        className="button-style"
      >
        Stars Per Assignment Grade Report
      </Button>
      <Button
        style={{ float: 'right', marginRight: 10 }}
        onClick={() => {
          history.push('percents');
        }}
        variant="success"
        className="button-style"
      >
        Percent Complete Grade Report
      </Button>
      <ViewTableLayout data={students} title="Students" />
      <Paginate totalPages={totalPages} changePageFunction={changePageFunction} />
    </MainLayout>

  );
};


ViewCourse.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ViewCourse;
