import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import {
  Form, Row, Col, Button, Alert,
} from 'react-bootstrap';
import apiWrapper from '../../api';
import MainLayout from '../main-layout';

const enrollementTypes = [
  { display: 'To Be Decided', value: 'tbd' },
  { display: 'Self', value: 'self' },
  { display: 'Schoology Email', value: 'sso_email' },
  { display: 'Schoology Username', value: 'sso_username' },
  { display: 'Email', value: 'email' },
  { display: 'Username', value: 'username' }
]
const CourseLayout = ({
  title, submitFunction, defaultValues, history,
}) => {
  const todayDate = (new Date()).toISOString();

  const [courseInformation, setCourseInformation] = useState({
    is_paid: true, price: 0, description: '', end_date: todayDate, enrollment_end_date: todayDate, enrollment_rules: '', enrollment_start_date: todayDate, enrollment_type: 'tbd', name: '', school_id: 0, short_name: '', start_date: todayDate, syllabus_code: 'ENG', teacher_ids: [],
  });
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [error, setError] = useState();
  

  useEffect(() => {
    apiWrapper.getSchools().then((res) => {
      setSchools(res.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
    });
    if (defaultValues) {
      const items = [];
      defaultValues.teachers.map((teacher) => {
        const item = {
          value: teacher.id,
          label: `${teacher.first_name} ${teacher.last_name}`,
        };
        return items.push(item);
      });

      const courseWithTeacherIds = {
        ...defaultValues,
        teacher_ids: defaultValues.teachers.map((teacher) => teacher.id),
      };

      setCourseInformation(courseWithTeacherIds);
      setTeacherOptions(items);
    }
  }, [defaultValues]);

  const onChangeValue = (e) => {
    setCourseInformation({ ...courseInformation, [e.target.name]: e.target.value });
  };

  const onChangeDate = (e) => {
    setCourseInformation(
      { ...courseInformation, [e.target.name]: moment(e.target.value).toISOString() },
    );
  };

  const onChangeIsPaid = (e) => {
    const isPaid = (e.target.value === 'true');
    setCourseInformation({ ...courseInformation, [e.target.name]: isPaid });
  };

  const onChangePrice = (e) => {
    const price = Number(e.target.value);
    setCourseInformation({ ...courseInformation, [e.target.name]: price });
  };

  const onChangeSelect = (e) => {
    setCourseInformation({ ...courseInformation, [e.target.name]: Number(e.target.value) });
  };

  const onEnrollmentSelect = (e) => {
    setCourseInformation({ ...courseInformation, [e.target.name]: e.target.value });
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitFunction(courseInformation).then(() => {
      history.replace('/');
    }).catch((err) => {
      setError(err.response.data.error.message);
      window.scrollTo(0, 0);
    });
  };

  const renderSchoolsOptions = () => schools.map((school) => (
    <option key={school.id} value={school.id}>{school.name}</option>
  ));

  const renderEnrollmentOptions = () => enrollementTypes.map((type, index) => (
    <option key={index + 1} value={type.value}>{type.display}</option>
  ));

  const onTeacherSelect = (newValue) => {
    const teacherIds = [];
    if (newValue) {
      newValue.map((teacher) => teacherIds.push(teacher.value));
    }
    setTeacherOptions(newValue);
    setCourseInformation({ ...courseInformation, teacher_ids: teacherIds });
  };

  const loadOptions = (inputValue, callback) => {
    apiWrapper.getTeachers(inputValue).then((res) => {
      const teachers = res.data.records.map((teacher) => ({
        value: teacher.id,
        label: `${teacher.first_name} ${teacher.last_name}`,
      }));
      callback(teachers);
    });
  };

  return (
    <MainLayout>
      <h1 className="mt-4">
        {title}
      </h1>
      {error && (
        <Alert className="text-center mt-3 p-1" variant="danger">
          <small>{`${error}.`}</small>
        </Alert>
      )}
      <p className="text-muted mt-3">All fields marked with an asterisk (*) have to be filled in.</p>
      <Form onSubmit={onFormSubmit}>
        <Form.Group className="mt-3" controlId="formSchool">
          <Form.Label>Choose a school*</Form.Label>
          <Form.Control as="select" name="school_id" value={courseInformation.school_id} onChange={onChangeSelect} required>
            <option disabled value={0}>Select option</option>
            {renderSchoolsOptions()}
          </Form.Control>
        </Form.Group>
        <Form.Group className="mt-3" controlId="formSchool">
          <Form.Label>Choose enrollment type*</Form.Label>
          <Form.Control as="select" name="enrollment_type" value={courseInformation.enrollment_type} onChange={onEnrollmentSelect} required>
            <option disabled value={0}>Select option</option>
            {renderEnrollmentOptions()}
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formName">
            <Form.Label>Name*</Form.Label>
            <Form.Control name="name" placeholder="Course name" value={courseInformation.name} onChange={onChangeValue} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formShortName">
            <Form.Label>Short name</Form.Label>
            <Form.Control name="short_name" value={courseInformation.short_name} onChange={onChangeValue} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formPrice">
            <Form.Label>Price</Form.Label>
              <div>
                <input 
                    type="radio" 
                    name="is_paid"
                    value
                    checked={courseInformation.is_paid === true}
                    onChange={onChangeIsPaid}
                /> 
                <span>Paid by teacher</span>
              </div>
              <div>
                <input 
                    type="radio" 
                    name="is_paid"
                    value={false}
                    checked={courseInformation.is_paid === false}
                    onChange={onChangeIsPaid}
                /> 
                <span>Paid by student</span>
              </div>
          </Form.Group>
          {!courseInformation.is_paid && (
            <Form.Group as={Col} controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control name="price" value={courseInformation.price} onChange={onChangePrice} />
            </Form.Group>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control name="description" value={courseInformation.description} as="textarea" rows="3" onChange={onChangeValue} style={{ resize: 'none' }} />
          </Form.Group>
        </Form.Row>
        <h4 className="mt-2">Enrollment dates</h4>
        <Form.Row>
          <Form.Group as={Col} controlId="formEnrollmentFrom">
            <Form.Label>From*</Form.Label>
            <Form.Control name="enrollment_start_date" value={moment(courseInformation.enrollment_start_date).format('YYYY-MM-DD')} type="date" onChange={onChangeDate} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formEnrollmentTo">
            <Form.Label>To*</Form.Label>
            <Form.Control name="enrollment_end_date" value={moment(courseInformation.enrollment_end_date).format('YYYY-MM-DD')} type="date" onChange={onChangeDate} required />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formEnrollmentRules">
          <Form.Label>Enrollment rules</Form.Label>
          <Form.Control name="enrollment_rules" value={courseInformation.enrollment_rules} as="textarea" rows="3" onChange={onChangeValue} style={{ resize: 'none' }} />
        </Form.Group>
        <h4 className="mt-2">Course dates</h4>
        <Form.Row>
          <Form.Group as={Col} controlId="formFrom">
            <Form.Label>From*</Form.Label>
            <Form.Control name="start_date" value={moment(courseInformation.start_date).format('YYYY-MM-DD')} type="date" onChange={onChangeDate} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formTo">
            <Form.Label>To*</Form.Label>
            <Form.Control name="end_date" value={moment(courseInformation.end_date).format('YYYY-MM-DD')} type="date" onChange={onChangeDate} required />
          </Form.Group>
        </Form.Row>
        <h4 className="mt-2">Teachers</h4>
        <Form.Group>
          <AsyncSelect
            value={teacherOptions}
            isMulti
            cacheOptions
            loadOptions={loadOptions}
            onChange={onTeacherSelect}
          />
        </Form.Group>
        <Row>
          <Col />
          <Col>
            <Button variant="success" className="w-100 mt-4 mb-5 button-style" type="submit">
            Submit
            </Button>
          </Col>
          <Col />
        </Row>

      </Form>
    </MainLayout>
  );
};

CourseLayout.propTypes = {
  title: PropTypes.string.isRequired,
  submitFunction: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    teachers: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    teacherIds: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

CourseLayout.defaultProps = {
  defaultValues: null,
};

export default CourseLayout;
