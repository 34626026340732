import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormInput = ({
  title, type, id, placeholder, onValueChange, value,
}) => (
  <Form.Group>
    <Form.Label className="text-light font-weight-bold">{title}</Form.Label>
    <Form.Control
      required
      id={id}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        onValueChange(e.target.value, id);
      }}
    />
  </Form.Group>
);

FormInput.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default FormInput;
