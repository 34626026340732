import React from 'react';
import Draggable from 'react-draggable';

const HintPeekLegend = ({ hintPeekData }) => (
  <Draggable bounds="parent">
    {hintPeekData.type === 'peek' ? (
      <div
        style={{
          height: '105px',
          width: '200px',
          background: 'white',
          borderRadius: '16px',
          boxShadow: '0 0 2px #000000',
          WebkitBoxShadow: '0 0 2px #000000',
          MozBoxShadow: '0 0 2px #000000',
          position: 'absolute',
          top: '3%',
          left: '3%',
          display: 'grid',
          padding: '5px 0 5px 0',
          zIndex: '100',
        }}
      >
        <h5 style={{ margin: '0px' }}><b>Peek</b></h5>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px' }}>
          <div
            style={{
              background: '#75fa4c',
              height: '2px',
              maxWidth: '70px',
              flex: '1',
              paddingTop: '5px',
            }}
          />
          <div
            style={{
              flex: '1',
              textAlign: 'start',
              marginLeft: '10px',
            }}
          >
            <b>Correct</b>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px' }}>
          <div
            className="legend-color"
            style={{
              background: '#ea3223',
              height: '2px',
              maxWidth: '70px',
              flex: '1',
              paddingTop: '5px',
            }}
          />
          <div
            style={{
              flex: '1',
              textAlign: 'start',
              marginLeft: '10px',
            }}
          >
            <b>Incorrect</b>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px' }}>
          <div
            style={{
              background: '#001cf5',
              height: '2px',
              maxWidth: '70px',
              flex: '1',
              paddingTop: '5px',
            }}
          />
          <div
            style={{
              flex: '1',
              textAlign: 'start',
              marginLeft: '10px',
            }}
          >
            <b>Missing</b>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          alignContent: 'center',
          height: '105px',
          width: '200px',
          background: 'white',
          borderRadius: '16px',
          boxShadow: '0 0 2px #000000',
          WebkitBoxShadow: '0 0 2px #000000',
          MozBoxShadow: '0 0 2px #000000',
          position: 'absolute',
          top: '3%',
          left: '3%',
          display: 'grid',
          padding: '5px 0 5px 0',
          zIndex: '100',
        }}
      >
        <h5 style={{ margin: '0px' }}><b>Hint</b></h5>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px' }}>
          <div style={{
            background: '#75fa4c',
            height: '2px',
            maxWidth: '70px',
            flex: '1',
            paddingTop: '5px',
          }}
          />
          <div
            style={{
              flex: '1',
              textAlign: 'start',
              marginLeft: '10px',
            }}
          >
            <b>Correct</b>
          </div>
        </div>
      </div>
    )}
  </Draggable>
);
export default HintPeekLegend;
