import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Table,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import Paginate from '../../components/paginate';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';

const Users = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [searchRequest, setSearchRequest] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    apiWrapper
      .getUsers(1)
      .then((res) => {
        setUsers(res.data.records);
        setTotalPages(res.data.pagination.total_page);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
  
  const changePageFunction = (number) => {
    setActivePage(number);
    apiWrapper.getUsers(number).then((res) => {
      setUsers(res.data.records);
    });
  };

  const renderUsers = () => users.map((user) => (
    <tr key={user.id}>
      <td>{user.account_level}</td>
      <td>{user.last_name}</td>
      <td>{user.first_name}</td>
      <td>{user.email}</td>
    </tr>
  ));

  const apiRequest = (query) => {
    if (query === "") {
      getAllUsers();
    } else {
      apiWrapper.searchUser(query).then((res) => {
        setUsers(res.data.records);
        setTotalPages(res.data.pagination.total_page);
      });
    }
  };

  const onChangeSearchRequest = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setSearchRequest(e.target.value);
    const query = e.target.value;
    setTypingTimeout(
      setTimeout(() => {
        apiRequest(query);
      }, 1000),
    );
  };

  const addTeacher = () => {
    history.push('/users/new-teacher');
  };

  return (
    <MainLayout>
      <div className="d-flex justify-content-between mt-4">
        <h1> Users </h1>
        <Button variant="success" className="align-self-center button-style" onClick={addTeacher}>
          Add Teacher
        </Button>
      </div>
      <InputGroup className="mt-4">
        <FormControl
          placeholder="Find a user"
          aria-label="Find a user"
          value={searchRequest}
          onChange={onChangeSearchRequest}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary">Search</Button>
        </InputGroup.Append>
      </InputGroup>
      <Table responsive className="mt-4">
        <thead>
          <tr>
            <th>Role</th>
            <th>Last name</th>
            <th>First name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>{renderUsers()}</tbody>
      </Table>
      <Paginate totalPages={totalPages} changePageFunction={changePageFunction} currPage={activePage}/>
    </MainLayout>
  );
};

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default Users;
