import React from 'react';
import PropTypes from 'prop-types';
import CourseLayout from '../../components/course-layout';
import apiWrapper from '../../api';

const EditCourse = ({ history, location }) => (
  <CourseLayout title="Edit A Course" history={history} submitFunction={apiWrapper.editCourse} defaultValues={location.state.course} />
);

EditCourse.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default EditCourse;
