import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import WebAppNavBar from '../navbar/web-app';

const WebAppLayout = ({ stars, history, children }) => (
  <>
    <WebAppNavBar stars={stars} history={history} />
    <Container>
      {children}
    </Container>
  </>
);

WebAppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WebAppLayout;
