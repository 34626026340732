import React from 'react';
import { Row } from 'react-bootstrap';
import formatString from '../../utils/custom-string-formatter';

const AssignmentIntroBlock = ({ introBlocks, block, index }) => {
  if (block.kind === 'title_subtitle' && !block.visited) {
    block.visited = true;
    return (
      <Row className="intro-block-title" style={{ width: '50%' }}>
        <div>
          <h1>{block.title}</h1>
          <h3>{block.text}</h3>
        </div>
      </Row>
    );
  }
  if (block.kind === 'one_third_image') {
    return (
      <Row style={{ width: '50%' }}>
        {introBlocks.map((item, idx) => {
          if (idx >= index && idx <= index + 2 && !item.visited && item.kind === 'one_third_image') {
            item.visited = true;
            return (
              <div style={{ textAlign: 'start' }} className="intro-block-third">
                <img className="intro-block-third-item" alt={item.alt} src={item.img} />
                {item.text && formatString(item.text)}
              </div>
            );
          }
        })}
      </Row>
    );
  } if (block.kind === 'half_image' && !block.visited) {
    return (
      <Row style={{ width: '50%' }}>
        {introBlocks.map((item, idx) => {
          if (idx >= index && idx <= index + 1 && !item.visited && item.kind === 'half_image') {
            item.visited = true;
            return (
              <div style={{ textAlign: 'start' }} className="intro-block-half">
                {item.img && <img alt={item.alt} className="intro-block-half-item" src={item.img} />}
                {item.text && formatString(item.text)}
              </div>
            );
          }
        })}
      </Row>
    );
  } if (block.kind === 'full_image' && !block.visited) {
    block.visited = true;
    return (
      <Row style={{ width: '50%' }}>
        <div style={{ textAlign: 'start' }} className="intro-block-full">
          <img alt={block.alt} className="intro-block-full-item" src={block.img} />
          <h4>{block.text}</h4>
        </div>
      </Row>
    );
  }

  return null;
};

export default AssignmentIntroBlock;
