/* eslint-disable no-script-url */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'react-bootstrap';
import './styles.css';

const Modal = ({
  handleClose, show, close, defaultButton = true, gradingStyle = false, children,
}) => {
  const showHideClassName = show ? 'modal d-block' : 'modal d-none';

  return (
    <div className={showHideClassName}>
      <div 
        className={gradingStyle ? 'grading-modal' : 'modal-container'}
        style={{
          marginTop: `${gradingStyle ? (window.innerHeight - 500) / 2 : (window.innerHeight - 475) / 2}px`,
          marginRight: `${gradingStyle ? (window.innerWidth - 400) / 2 : (window.innerWidth - 950) / 2}px`,
          marginLeft: `${gradingStyle ? (window.innerWidth - 400) / 2 : (window.innerWidth - 950) / 2}px`,
          marginBottom: `${gradingStyle ? (window.innerHeight - 500) / 2 : (window.innerHeight - 475) / 2}px`
        }}
      >
        {close !== 'button' ? (
          <>
            {close !== 'loading'
            && (
            <>
              <a href="javascript:;" className="modal-close" onClick={handleClose}>
                X
              </a>
            </>
            )}
            {children}
          </>
        )
          : (
            <>
              {children}
              {defaultButton && (
              <Button variant="success" className="align-self-center submit button-style" onClick={handleClose}>
              Close
              </Button>
              )}
            </>
          )}

      </div>
    </div>
  );
};

export default Modal;
