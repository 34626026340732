/* eslint-disable no-script-url */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'react-bootstrap';
import './styles.css';

const WebAppPopUp = ({
  handleClose, show, close, defaultButton, isMobile, isNewAccount, children,
}) => {
  const showHideClassName = show ? 'modal d-block' : 'modal d-none';
  const determineDimensions = () => {
    let modalHeight, modalWidth; 
    if (isMobile && isNewAccount) {
      modalHeight = '575px';
      modalWidth = '375px';
    } else if (isMobile) {
      modalHeight = '475px';
      modalWidth = '375px';
    } else {
      modalWidth = '712px';
      modalHeight = '300px';
    }
    return { 
      modalHeight,
      modalWidth,
      verticalMargin: (window.innerHeight - modalHeight.substring(0,3)) / 2,
      horizontalMargin: (window.innerWidth - modalWidth.substring(0,3)) / 2
    }
  }

  return (
    <div className={showHideClassName}>
      <div 
        className={isMobile ? 'modal-container-mobile' : 'modal-container-web'}
        style={{ 
          marginTop: determineDimensions().verticalMargin,
          marginRight: determineDimensions().horizontalMargin,
          marginLeft: determineDimensions().horizontalMargin,
          marginBottom: determineDimensions().verticalMargin,
          height: determineDimensions().modalHeight, 
          width: determineDimensions().modalWidth
        }}
      >
        {close !== 'button' ? (
          <>
            {close !== 'loading'
            && (
            <>
              <a href="javascript:;" className="modal-close" onClick={handleClose}>
                X
              </a>
            </>
            )}
            {children}
          </>
        )
          : (
            <>
              {children}
              {defaultButton && (
              <Button variant="success" className="align-self-center submit button-style" onClick={handleClose}>
              Close
              </Button>
              )}
            </>
          )}

      </div>
    </div>
  );
};

export default WebAppPopUp;
