export let currentSketch = [];

const setCurrentSketch = (value) => {
  currentSketch = value;
};

export let currentSketchTime = 0;

const setCurrentSketchTime = (value) => {
  currentSketchTime = value;
}

export { setCurrentSketch, setCurrentSketchTime };

