import React from 'react';
import PropTypes from 'prop-types';
import CourseLayout from '../../components/course-layout';
import apiWrapper from '../../api';

const NewCourse = ({ history }) => (
  <CourseLayout history={history} title="Add a new course" submitFunction={apiWrapper.addCourse} />
);

NewCourse.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default NewCourse;
