import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import NavBar from '../navbar';

const MainLayout = ({ history, children }) => (
  <div>
    <NavBar history={history} />
    <Container>
      { children }
    </Container>
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
