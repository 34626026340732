import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import FormInput from '../../components/form-input';
import FullLogo from '../../resources/images/full_logo.svg';
import './styles.css';

const InvitationUsername = ({ history, location }) => {
    const [message, setMessage] = useState('Enter your username your teacher has provided.');
    const [username, setUsername] = useState('');

    const onValueChange = (value) => {
        setUsername(value);
    };

    return (
        <div className="main-container">
            <div className="form-width">
                <img src={FullLogo} className="w-100" alt="Logo" />
                <Alert className="text-center mt-3 p-1" variant="success">
                    {message}
                </Alert>
                <Form onSubmit={() => { history.push(`/invitation/${username}/password`) }} className="w-100 mt-3">
                    <FormInput
                        type="text"
                        id="username"
                        value={username}
                        placeholder="Username"
                        onValueChange={onValueChange}
                    />
                    <Button variant="success" className="w-100 mt-3 button-style" type="submit">
                        Next
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default InvitationUsername;