import React, { useContext } from 'react';
import {
  Navbar, Nav, Button, Spinner,
} from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import './styles.css';
import FullLogo from '../../resources/images/full_logo.svg';
import { authContext } from '../../contexts/auth-context';

import apiWrapper from '../../api';
import { webAppContext } from '../../contexts/web-app-context';

const NavBar = ({ history }) => {
  const { setAuthData, auth } = useContext(authContext);
  const { dataLoading, enrollment, setUserActiveEnrollment } = useContext(webAppContext);
  const currHistory = useHistory();
  const teacherDemoAccountCode = 'bPVXH';

  const onSignOut = () => {
    setAuthData(null);
  };

  const switchToSpatialVisView = () => {
    if (!history) currHistory.push('/spatial-vis-web', { addNewEnrollment: false });
    else if (!enrollment) {
      apiWrapper.enrollWithFreeCoupon(teacherDemoAccountCode).then((newEnrollment) => {
        setUserActiveEnrollment(newEnrollment.data);
        history.push('/spatial-vis-web', { addNewEnrollment: false });
      });
    } else history.push('/spatial-vis-web', { addNewEnrollment: false });
  };

  return (
    <Navbar expand="lg" variant="dark" className="main-navbar">
      <Navbar.Brand as={NavLink} to="/">
        <img src={FullLogo} width="200" alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {auth.user.account_level === 'admin' && (
            <>
              <Nav.Link as={NavLink} activeClassName="active" exact to="/">
                Courses
              </Nav.Link>
              <Nav.Link as={NavLink} activeClassName="active" to="/schools">
                Schools
              </Nav.Link>
              <Nav.Link as={NavLink} activeClassName="active" to="/users">
                Users
              </Nav.Link>
              <Nav.Link as={NavLink} activeClassName="active" exact to="/user-management">
                User Management
              </Nav.Link>
              <Nav.Link as={NavLink} activeClassName="active" exact to="/coupons">
                Coupons
              </Nav.Link>
            </>

          )}
          {auth.user.account_level === 'teacher'
            && (
              <>
                <Button
                  variant="success"
                  className="button-style"
                  onClick={switchToSpatialVisView}
                >
                  {dataLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : 'Spatial Vis View'}
                </Button>
                <Nav.Link as={NavLink} activeClassName="active" exact to="/">
                  Overview
                </Nav.Link>
                <Nav.Link as={NavLink} activeClassName="active" exact to="/rostering">
                  Roster
                </Nav.Link>
                <Nav.Link as={NavLink} activeClassName="active" to="/assignment-sets">
                  Assignment Sets
                </Nav.Link>
              </>
            )}
          {(auth.user.account_level === 'student' && enrollment)
            ? (
              <>
                <Button
                  variant="success"
                  className="button-style"
                  onClick={switchToSpatialVisView}
                  style={{ marginRight: '10px' }}
                >
                  Back to Spatial Vis
                </Button>
              </>
            ) : (<></>)}
          <Button variant="outline-light" onClick={onSignOut}>
            Log out
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
