import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import moment from 'moment';
import 'moment-duration-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPlus } from '@fortawesome/free-solid-svg-icons';
import apiWrapper from '../../api';
import MainLayout from '../../components/main-layout';
import './styles.css';

const AssignmentSets = ({ history }) => {
  const [assignmentSets, setAssignmentSets] = useState([]);
  const [courses, setCourses] = useState([]);
  const [assignmentsLoaded, setAssignmentsLoaded] = useState(false);

  useEffect(() => {
    setAssignmentsLoaded(true);
    apiWrapper.getAssignmentSets().then((res) => {
      setAssignmentSets(res.data);
      setAssignmentsLoaded(false);
    });
    apiWrapper.getCourses().then((res) => {
      setCourses(res.data);
    });
  }, []);

  const onNewAssignmentSet = () => {
    history.push('/assignment-sets/create');
  };

  const onEditAssignmentSet = (chosenAssignmentSet, isPreset) => {
    if (isPreset) history.push('/assignment-sets/edit', { chosenAssignmentSet, page: 12 });
    else history.push('/assignment-sets/edit', { chosenAssignmentSet });
  };

  const renderCoursesAssignedTo = (assignmentSet) => {
    let coursesStr = '';
    courses.forEach(course => {
      if (course.assignment_set_id === assignmentSet.id) {
        if (coursesStr) coursesStr += ', ' + course.name;
        else coursesStr += course.name
      }
    })
    if (!coursesStr) return (<p>none</p>);
    else return (<p>{coursesStr}</p>);
  }

  const renderAssignmentSets = (preset) => assignmentSets.map((assignmentSet) => {
    if (assignmentSet.preset === preset) {
      return (
        <div key={assignmentSet.id} className={preset ? "preset-assignment-sets d-flex flex-column p-2 assignments-container m-3" : "d-flex flex-column p-2 assignments-container m-3"} style={{ width: 230 }}>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">{assignmentSet.name}</h5>
            <Button variant="link" onClick={() => { onEditAssignmentSet(assignmentSet, preset); }}>
              <FontAwesomeIcon icon={faCogs} color="black" />
            </Button>
          </div>
          <div className="d-flex">
            <b>Total Assignments:</b>
            <p className="ml-2">{assignmentSet.assignments.length}</p>
          </div>
          <div className="d-flex">
            <b>Average Time:</b>
            <p className="ml-2">{moment.duration(assignmentSet.average_time, 'seconds').format('h[h] m[m] s[s]')}</p>
          </div>
          <div className="d-flex">
            <b>Assigned To:</b>
            <p className="ml-2">{renderCoursesAssignedTo(assignmentSet)}</p>
          </div>
        </div>
      );
    }
  });

  return (
    <MainLayout history={history}>
      <Row className="mt-4">
        <Col>
          <h1>Preset Assignment Sets</h1>
        </Col>
      </Row>
      {assignmentSets && (
        <Row className="mt-2 mb-5">
          <Col>
            <div className="d-flex flex-wrap">
              {assignmentsLoaded ? (<>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner></>) : (<>{renderAssignmentSets(true)}</>)}
            </div>
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col>
          <h1>Custom Assignment Sets</h1>
          <p>Specify which assignments students are required to complete.</p>
        </Col>
      </Row>
      {assignmentSets && (
        <Row className="mt-2 mb-5">
          <Col>
            <div className="d-flex flex-wrap">
              {assignmentsLoaded ? (<>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner></>) : (<>{renderAssignmentSets(false)}</>)}
              <div className="d-flex flex-column p-2 assignments-container m-3" style={{ width: 230 }}>
                <Button onClick={onNewAssignmentSet} className="d-flex align-items-center justify-content-center flex-column button-no-style">
                  <div className="plus-circle d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faPlus} color="black" />
                  </div>
                  <b className="text-dark mt-2">Add New Custom Assignment Set</b>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default AssignmentSets;
