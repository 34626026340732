import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useParams,
} from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import moment from 'moment';
import { COLUMN_TO_NAME_MAP } from '../../utils/grade-downloader';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';

const PercentsGradings = () => {
  const [gradings, setGradings] = useState([]);
  const [course, setCourse] = useState({});
  const { id } = useParams();

  useEffect(() => {
    apiWrapper.getPercentGradings(id).then((res) => {
      setGradings(res.data);
    });

    apiWrapper.getCourse(id).then((res) => {
      setCourse(res.data);
    });
  }, [id]);

  const toCSV = (json) => {
    let csv = '';

    // CSV Header
    const keys = (json[0] && Object.keys(json[0])) || [];
    csv += `${keys.map((key) => COLUMN_TO_NAME_MAP[key].label).join(',')}\n`;

    // CSV Data
    json.forEach((line) => {
      csv += `${keys.map((key) => line[key]).join(',')}\n`;
    });

    return csv;
  };

  const downloadAsCSV = () => {
    const csv = toCSV(gradings);
    const encodedUri = window.encodeURI(`data:text/csv;charset=utf-8,${csv}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `percent_complete_${course.code}_${moment().format()}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const completedCourseCount = (grading) => {
    const courseCount = Object.values(COLUMN_TO_NAME_MAP).reduce((acc, value) => {
      if (value.course) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const completedCount = Object.keys(COLUMN_TO_NAME_MAP).reduce((acc, key) => {
      const value = COLUMN_TO_NAME_MAP[key];
      if (value.course) {
        if (grading[key] === 100) {
          return acc + 1;
        }
      }
      return acc;
    }, 0);

    return `${completedCount}/${courseCount}`;
  };

  const renderData = () => gradings.map((grading) => (
    <tr key={grading.user_id}>
      <td>{grading.first_name}</td>
      <td>{grading.last_name}</td>
      <td>{grading.email}</td>
      <td>{completedCourseCount(grading)}</td>
    </tr>
  ));

  return (
    <MainLayout>
      <h2 className="mt-4">
        Percent Complete Grade Report
      </h2>

      <Button className="button-style" style={{ float: 'right' }} onClick={downloadAsCSV} variant="success">
        Download Detailed Summary
      </Button>

      <Table responsive bordered className="mt-1">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Completed Lessons</th>
          </tr>
        </thead>
        <tbody>
          {renderData()}
        </tbody>
      </Table>
    </MainLayout>
  );
};

PercentsGradings.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default PercentsGradings;
