import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const Paginate = ({ changePageFunction, totalPages, currPage }) => {
  const [activePage, setActivePage] = useState(currPage);
  const pages = [];

  const changePage = (number) => {
    changePageFunction(number);
    setActivePage(number);
  };

  const onPreviousPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
      changePage(activePage - 1);
    }
  };

  const onNextPage = () => {
    if (activePage < totalPages) {
      setActivePage(activePage + 1);
      changePage(activePage + 1);
    }
  };

  const renderPageNums = () => {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item key={i} active={i === currPage} onClick={() => { if (i !== currPage) changePage(i) }}>
          {i}
        </Pagination.Item>
      );
    }
    return pages;
  }

  return (
    <Pagination className="justify-content-center mt-4">
      <Pagination.Prev disabled={activePage === 1} onClick={onPreviousPage} />
      {renderPageNums()}
      <Pagination.Next
        disabled={(activePage === totalPages) || (totalPages === 0)}
        onClick={onNextPage}
      />
    </Pagination>

  );
};

Paginate.propTypes = {
  changePageFunction: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Paginate;
