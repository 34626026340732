import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {
  Button, Form, Alert, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import apiWrapper from '../../api';
import { authContext } from '../../contexts/auth-context';

const CheckoutForm = ({
  stripe, price, code, history,
}) => {
  const { auth, setAuthData } = useContext(authContext);
  const [cardElement, setCardElement] = useState();
  const [error, setError] = useState();
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [loading, setLoading] = useState({
    coupon: false,
    payment: false,
  });
  const [couponId, setCouponId] = useState('');
  const [discount, setDiscount] = useState();
  const [cost, setCost] = useState(price);
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    setCost(price);
  }, [price]);

  const onValueChange = (value) => {
    setCouponId(value);
  };

  const handleCardElement = (element) => {
    setCardElement(element);
  };

  const onApplyCoupon = () => {
    setError();
    setLoading({
      ...loading,
      coupon: true,
    });
    apiWrapper.getCoupon(couponId).then((res) => {
      if (res.data.metadata.times_redeemed == 1) {
        setError('Coupon has already been used');
        setLoading({
          ...loading,
          coupon: false,
        });
      } else {
        setCoupon(res.data);
        setDiscount(res.data.percent_off / 100);
        const discountPrice = price - (price * (res.data.percent_off / 100));
        setCost(discountPrice);
        setLoading({
          ...loading,
          coupon: false,
        });
      }
    }).catch(() => {
      setError('Invalid coupon');
      setLoading({
        ...loading,
        coupon: false,
      });
    });
  };

  const onCardSubmit = () => {
    setError();
    setLoading({
      ...loading,
      payment: true,
    });
    if (discount === 1) {
      apiWrapper.enrollWithFreeCoupon(code).then(() => {
        apiWrapper.updateCoupon(couponId, coupon.times_redeemed).then(() => {
          setLoading({
            ...loading,
            payment: false,
          });
          setAuthData({
            ...auth,
            user: { ...auth.user, has_paid: true },
          });
          history.push('/spatial-vis-web');
        });
      }).catch((err) => {
        setError(err.response.data.error.message);
        setLoading({
          ...loading,
          payment: false,
        });
      });
    } else {
      let clientSecret;
      apiWrapper.createIntent(code, discount).then((res) => {
        clientSecret = res.data.client_secret;
        stripe.handleCardPayment(
          clientSecret,
          cardElement,
        ).then((result) => {
          if (result.error) {
            setError(result.error.message);
            setLoading({
              ...loading,
              payment: false,
            });
          } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
            setPaymentSucceeded(true);
            if (discount > 0) {
              apiWrapper.updateCoupon(couponId, coupon.times_redeemed).then(() => {
                setLoading({
                  ...loading,
                  payment: false,
                });
                setAuthData({
                  ...auth,
                  user: { ...auth.user, has_paid: true },
                });
                history.push('/spatial-vis-web');
              });
            } else {
              setLoading({
                ...loading,
                payment: false,
              });
              setAuthData({
                ...auth,
                user: { ...auth.user, has_paid: true },
              });
              history.push('/spatial-vis-web');
            }
          }
        });
      }).catch((err) => {
        setError(err.response.data.error.message);
        setLoading({
          ...loading,
          payment: false,
        });
      });
    }
  };

  if (paymentSucceeded) {
    return (
      <div className="card-info">
        Payment succeeded. You are now enrolled to the course.
      </div>
    );
  }

  return (
    <div>
      <p className="card-info">Enter your card information to pay a course.</p>
      <CardElement onReady={handleCardElement} className="card-number-container" />
      {error && (
        <Alert variant="danger" className="text-center p-1 mt-2 mb-0">
          <small>{error}</small>
        </Alert>
      )}
      <Form className="w-100 mt-3">
        <div style={{ display: 'flex' }}>
          <Form.Control
            id="Coupon"
            type="text"
            value={couponId}
            placeholder="Enter coupon"
            onChange={(e) => {
              onValueChange(e.target.value);
            }}
          />
          <Button
            onClick={() => { onApplyCoupon(); }}
            variant="success"
            className="w-100 mt-3 button-style apply-coupon-btn"
          >
            Apply &nbsp;
            {loading.coupon && (
              <Spinner size="sm" animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Button>
        </div>
      </Form>
      <Button className="pay-button mt-4" onClick={onCardSubmit}>
        {loading.payment ? (
          <FontAwesomeIcon icon={faSpinner} spin color="white" />
        ) : (
          `Pay $${cost}`
        )}
      </Button>
    </div>
  );
};

CheckoutForm.propTypes = {
  price: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  stripe: PropTypes.shape({
    handleCardPayment: PropTypes.func,
  }).isRequired,
};

export default injectStripe(CheckoutForm);
