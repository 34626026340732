import React from 'react';
import PropTypes from 'prop-types';
import SchoolLayout from '../../components/school-layout';
import apiWrapper from '../../api';

const EditSchool = ({ location, history }) => (
  <SchoolLayout title="Edit a school" submitFunction={apiWrapper.editSchool} history={history} defaultValues={location.state.school} />
);

EditSchool.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default EditSchool;
