import React from 'react';
import PropTypes from 'prop-types';
import ResetPassword from '../../components/reset-password';

const TeacherOnboarding = ({ history }) => (
  <ResetPassword history={history} message="Welcome to Spatial Vis! To create your account, please choose a password." />
);

TeacherOnboarding.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default TeacherOnboarding;
