import React, {useContext, useEffect } from 'react';
import { authContext } from '../../contexts/auth-context';
import { useParams } from 'react-router-dom';
import apiWrapper from '../../api';

const SingleSignOn = ({ history }) => {
  const { auth, setAuthData } = useContext(authContext);
  const { token } = useParams();

  useEffect(() => {
    const updateAuthData = async () => {
      setAuthData({
        ...auth,
        token: token
      });
      const user = await apiWrapper.getCurrentUser();
      setAuthData({
        user: user.data,
        token
      });
      history.push(`/spatial-vis-web`);
    }
    updateAuthData();
  }, []);

  return (
    <div className="main-container">
      
    </div>
  );
}

export default SingleSignOn;