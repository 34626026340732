import React from 'react';
import PropTypes from 'prop-types';
import apiWrapper from '../../api';
import UserLayout from '../../components/user-layout';


const NewTeacher = ({ history }) => (
  <UserLayout title="Add a New Teacher" history={history} submitFunction={apiWrapper.addTeacher} />
);

NewTeacher.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default NewTeacher;
