import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Alert,
} from 'react-bootstrap';
import MainLayout from '../main-layout';

const SchoolLayout = ({
  title, submitFunction, defaultValues, history,
}) => {
  const [fullAddress, setFullAddress] = useState({
    name: '', address: '', city: '', zip_code: '', country: '', state_code: '', description: '', notes: '', province: '', short_name: '',
  });

  const [error, setError] = useState();

  useEffect(() => {
    if (defaultValues) {
      setFullAddress(defaultValues);
    }
  }, [defaultValues]);

  const onChangeValue = (e) => {
    setFullAddress({ ...fullAddress, [e.target.name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitFunction(fullAddress).then(() => {
      history.replace('/schools');
    }).catch((err) => {
      setError(err.response.data.error.message);
    });
  };


  return (
    <MainLayout>
      <h1 className="mt-4">
        {title}
      </h1>
      {error && (
        <Alert className="text-center mt-3 p-1" variant="danger">
          <small>{`${error}.`}</small>
        </Alert>
      )}
      <p className="text-muted mt-3">All fields marked with an asterisk (*) have to be filled in.</p>
      <Form onSubmit={onFormSubmit}>
        <Form.Row className="mt-3">
          <Form.Group as={Col} controlId="formName">
            <Form.Label>Name*</Form.Label>
            <Form.Control name="name" placeholder="School name" value={fullAddress.name} onChange={onChangeValue} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formShortName">
            <Form.Label>Short name</Form.Label>
            <Form.Control name="short_name" value={fullAddress.short_name} onChange={onChangeValue} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formAddress">
            <Form.Label>Address*</Form.Label>
            <Form.Control name="address" placeholder="School address" value={fullAddress.address} onChange={onChangeValue} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formProvince">
            <Form.Label>Province</Form.Label>
            <Form.Control name="province" value={fullAddress.province} onChange={onChangeValue} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formCity">
            <Form.Label>City*</Form.Label>
            <Form.Control name="city" value={fullAddress.city} onChange={onChangeValue} required />
          </Form.Group>

          <Form.Group as={Col} controlId="formState">
            <Form.Label>State Code*</Form.Label>
            <Form.Control name="state_code" value={fullAddress.state_code} onChange={onChangeValue} required />
          </Form.Group>

          <Form.Group as={Col} controlId="formZip">
            <Form.Label>Zip*</Form.Label>
            <Form.Control name="zip_code" value={fullAddress.zip_code} onChange={onChangeValue} required />
          </Form.Group>

          <Form.Group as={Col} controlId="formCountry">
            <Form.Label>Country*</Form.Label>
            <Form.Control name="country" value={fullAddress.country} onChange={onChangeValue} required />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control name="description" value={fullAddress.description} as="textarea" rows="3" onChange={onChangeValue} style={{ resize: 'none' }} />
          </Form.Group>
          <Form.Group as={Col} controlId="formNotes">
            <Form.Label>Notes</Form.Label>
            <Form.Control name="notes" value={fullAddress.notes} as="textarea" rows="3" onChange={onChangeValue} style={{ resize: 'none' }} />
          </Form.Group>

        </Form.Row>
        <Row>
          <Col />
          <Col>
            <Button variant="success" className="w-100 mt-3 button-style" type="submit">
            Submit
            </Button>
          </Col>
          <Col />
        </Row>

      </Form>
    </MainLayout>
  );
};

SchoolLayout.propTypes = {
  title: PropTypes.string.isRequired,
  submitFunction: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

SchoolLayout.defaultProps = {
  defaultValues: null,
};

export default SchoolLayout;
