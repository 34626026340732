import React, { useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import FormInput from '../../components/form-input';
import FullLogo from '../../resources/images/full_logo.svg';
import './styles.css';

const StudentOnboarding = ({ history, location }) => {
    const [message, setMessage] = useState('Enter the course code your teacher has provided.');
    const [courseCode, setCourseCode] = useState('');
    const successMessage = "Success! If you are on a computer, log in below to access the web app. If you are on a mobile device, download the iOS or Android mobile app and log in."
    const onValueChange = (value) => {
        setCourseCode(value);
    };
    const [didSubmit, setDidSubmit] = useState(false);

    useEffect(() => {
        if (location.state !== undefined) {
          setDidSubmit(location.state.success);
          if (location.state.success) history.replace('/sign-in', { flash: successMessage });
        }
    }, []);

    return (
        <div className="main-container">
            <div className="form-width">
                <img src={FullLogo} className="w-100" alt="Logo" />

                {
                    didSubmit ? (
                      <>
                        <Alert className="text-center mt-3 p-1" variant="success">
                          {successMessage}
                        </Alert>
                      </>
                    ) :
                        (
                            <>
                                <Alert className="text-center mt-3 p-1" variant="success">
                                    {message}
                                </Alert>
                                <Form onSubmit={() => { history.push('/invitation/username', { courseCode: courseCode }) }} className="w-100 mt-3">
                                    <FormInput
                                        type="text"
                                        id="courseCode"
                                        value={courseCode}
                                        placeholder="Course Code"
                                        onValueChange={onValueChange}
                                    />
                                    <Button variant="success" className="w-100 mt-3 button-style" type="submit">
                                        Next
                            </Button>
                                </Form>
                            </>
                        )
                }

            </div>
        </div>
    );
}

export default StudentOnboarding;
