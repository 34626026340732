import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import FullLogo from '../../resources/images/full_logo.svg';
import LogInInput from '../../components/form-input';
import apiWrapper from '../../api';

import './request.css';

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('Please enter your email address to reset your password.');

  const onSubmit = (e) => {
    e.preventDefault();
    apiWrapper.requestPasswordChange(email).then(() => {
      setMessage('An email has been sent. Please check your inbox to reset your account password.');
      setError('');
    }).catch((err) => {
      setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
    });
  };

  const onValueChange = (value) => {
    setEmail(value);
  };

  return (
    <div className="main-container">
      <div className="form-width">
        <img src={FullLogo} className="w-100" alt="Logo" />
        {error && (
          <Alert className="text-center mt-3 p-1" variant="danger">
            <small>{`${error}.`}</small>
          </Alert>
        )}
        <Form onSubmit={onSubmit} className="w-100 mt-3">
          <Alert className="text-center mt-3 p-1" variant="info">
            {message}
          </Alert>
          <LogInInput
            title="Email"
            type="email"
            value={email}
            placeholder="Email"
            onValueChange={onValueChange}
          />
          <Button variant="success" className="w-100 mt-3 button-style" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
