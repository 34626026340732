import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Container, Row, Col, Alert,
} from 'react-bootstrap';
import FullLogo from '../../resources/images/full_logo.svg';
import FormInput from '../../components/form-input';
import './styles.css';
import apiWrapper from '../../api';

const SignUp = ({ history }) => {
  const [newUser, setNewUser] = useState({
    firstName: '', lastName: '', email: '', password: '', passwordConfirmation: '',
  });
  const [error, setError] = useState();
  const onSubmit = (e) => {
    e.preventDefault();
    if (newUser.password !== newUser.passwordConfirmation) {
      setError('The password and its confirmation do not match');
      return;
    }
    apiWrapper.onSignUp(
      newUser.firstName, newUser.lastName, newUser.email.toLowerCase(), newUser.password,
    ).then(() => {
      history.replace('/sign-in', { flash: 'Thank you for signing up. You will receive an email with instructions for how to confirm your email address in a few minutes.' });
    }).catch((err) => {
      setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
    });
  };

  const onValueChange = (value, id) => {
    setNewUser({ ...newUser, [id]: value });
  };
  return (
    <div className="main-container">
      <div className="sign-up-form-width">
        <div className="text-center">
          <img src={FullLogo} width={300} alt="Logo" />
        </div>
        {error && (
          <Alert className="text-center mt-3 p-1" variant="danger">
            <small>{`${error}.`}</small>
          </Alert>
        )}
        <Form onSubmit={onSubmit} className="w-100 mt-3">
          <Container className="p-0">
            <Row>
              <Col>
                <FormInput
                  title="First name"
                  type="text"
                  id="firstName"
                  placeholder="First name"
                  value={newUser.firstName}
                  onValueChange={onValueChange}
                />
              </Col>
              <Col>
                <FormInput
                  title="Last name"
                  type="text"
                  id="lastName"
                  placeholder="Last name"
                  value={newUser.lastName}
                  onValueChange={onValueChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  title="Email"
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  value={newUser.email}
                  onValueChange={onValueChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  title="Password"
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={newUser.password}
                  onValueChange={onValueChange}
                />
              </Col>
              <Col>
                <FormInput
                  title="Confirm your password"
                  type="password"
                  id="passwordConfirmation"
                  placeholder="Password confirmation"
                  value={newUser.passwordConfirmation}
                  onValueChange={onValueChange}
                />
              </Col>
            </Row>
          </Container>
          <Button variant="success" className="w-100 mt-3 button-style" type="submit">
            Sign up
          </Button>
        </Form>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default SignUp;
