import React from 'react';

const formatString = (string) => {
  if (string.indexOf('*') >= 0 || string.indexOf('\n') >= 0) {
    const lines = string.split('\n');
    return (
      <div>
        {lines.map((line) => {
          let count = line.split('*').length - 1;
          if (count > 2) { // only for case Introduction Lesson 7
            const bolded = [];
            let next = line;
            while (count > 0) {
              const index = next.indexOf('*');
              next = `${next.substring(0, index)}${next.substring(index + 1)}`;
              bolded.push(index);
              count -= 1;
            }
            return (
              <h4>
                {next.substring(0, bolded[0])}
                <b>{next.substring(bolded[0], bolded[1])}</b>
                {next.substring(bolded[1], bolded[2])}
                <b>{next.substring(bolded[2], bolded[3])}</b>
                {next.substring(bolded[3])}
              </h4>
            );
          }
          const bolded = line.substring(line.indexOf('*') + 1, line.lastIndexOf('*'));
          const prev = line.substring(0, line.indexOf('*'));
          line = line.substring(line.lastIndexOf('*') + 1);
          line = line.replaceAll('\\n', '');
          return (
            <h4>
              {prev}
              <b>{bolded}</b>
              {line}
            </h4>
          );
        })}
      </div>
    );
  }
  return (
    <h4>{string}</h4>
  );
};

export default formatString;
