/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import 'moment-duration-format';

const Assignments = ({
  lesson, assignments, index, onNext, assignmentsSelected, onBack, preset, byNavigate,
}) => {
  const [chosenAssignmentIds, setChosenAssignmentIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const totalAverageTime = chosenAssignmentIds.reduce((acc, assignmentId) => {
    const assignment = assignments.find((assign) => assign.id === assignmentId);
    if (assignment) {
      return acc + assignment.average_time;
    }
    return acc;
  }, 0);
  const [assignmentsToggled, setAssignmentsToggled] = useState(false);

  useEffect(() => {
    setAssignmentsToggled(false);
    if (assignmentsSelected.length > 0) {
      const foundAssignments = assignmentsSelected.find((item) => item.lesson.id === lesson.id);
      if (foundAssignments) {
        setChosenAssignmentIds(foundAssignments.ids);
        if (byNavigate) onAssignmentNavigate(foundAssignments.ids);
      }
    }
  }, [lesson, assignmentsSelected]);

  const onAssignmentToggle = (chosenId) => {
    setAssignmentsToggled(true);
    const alreadyExist = chosenAssignmentIds.find((id) => id === chosenId);
    if (alreadyExist) {
      const newIds = chosenAssignmentIds.filter((id) => id !== chosenId);
      setChosenAssignmentIds(newIds);
    } else {
      setChosenAssignmentIds([...chosenAssignmentIds, chosenId]);
    }
  };

  const onAllSelect = () => {
    setAssignmentsToggled(true);
    setIsAllSelected(!isAllSelected);
    if (!isAllSelected === true) {
      const newChosenIds = assignments.filter((assignment) => assignment.kind !== 'tutorial').map((assignment) => assignment.id);
      const newTotalAverageTime = assignments.filter(
        (assignment) => assignment.kind !== 'tutorial',
      ).reduce(
        (accumulator, assignment) => accumulator + assignment.average_time, 0,
      );
      setChosenAssignmentIds(newChosenIds);
    } else {
      setChosenAssignmentIds([]);
    }
  };

  const onSave = (e) => {
    onAssignmentSubmit(e, true);
  };

  const onSaveNext = (e) => {
    onAssignmentSubmit(e, false);
  };

  const onAssignmentSubmit = (e, justSave) => {
    e.preventDefault();
    onNext(chosenAssignmentIds, totalAverageTime, lesson, justSave);
    setChosenAssignmentIds([]);
    setIsAllSelected(false);
    setAssignmentsToggled(false);
  };

  const onAssignmentNavigate = (ids) => {
    byNavigate(ids, totalAverageTime, lesson);
    setChosenAssignmentIds([]);
    setIsAllSelected(false);
  };

  const onAssignmentReturn = (e) => {
    e.preventDefault();
    onBack(chosenAssignmentIds, totalAverageTime, lesson);
    setChosenAssignmentIds([]);
    setIsAllSelected(false);
  };

  return (
    <>
      {assignmentsToggled &&
      <Alert className="text-center mt-2 p-1" variant="warning">
        Would you like to save your changes before navigating to another step?
        <div className="mt-3 d-flex justify-content-left mb-2">
          <Button className="button-style button-save" variant="success" onClick={onSave}>
            Save
          </Button>
          <Button className="button-cancel" variant="danger" onClick={() => { setAssignmentsToggled(false); }}>
            Don&apos;t Save
          </Button>
        </div>
      </Alert>}
      <b className="assignments-font-size">{`${index + 2}. Select the required assignments from Lesson ${index}: ${lesson.name}`}</b>
      <Form className="mt-2" onSubmit={onSaveNext}>
        <div className="assignments-container">
          <b className="text-decoration assignments-font-size">{`Lesson ${index}: ${lesson.name}`}</b>
          <Form.Group>
            <Form.Check disabled={preset} onChange={onAllSelect} className="checkbox font-weight-bold mt-3" checked={isAllSelected} type="checkbox" label={`Select All Assignments In ${index}: ${lesson.name}`} />
          </Form.Group>
          <div className="d-flex justify-content-between mb-3">
            <b className="text-primary assignments-font-size">Name of the Assignment</b>
            <b className="text-primary assignments-font-size">Average Time</b>
          </div>
          {assignments.map((assignment) => {
            const totalAssignmentLength = assignments.length;
            if (assignment.kind === 'tutorial') {
              return null;
            }
            const duration = moment.duration(assignment.average_time, 'seconds');
            const formatted = duration.format('h[h] m[m] s[s]');

            const isChosen = chosenAssignmentIds.some((id) => id === assignment.id);

            return (
              <Form.Group key={assignment.id} className="d-flex justify-content-between">
                <Form.Check
                  onChange={() => { onAssignmentToggle(assignment.id); }}
                  checked={isChosen}
                  disabled={preset}
                  type="checkbox"
                  className="text-capitalize checkbox"
                  label={`${lesson.short_name} ${assignment.display_order}/${totalAssignmentLength} ${assignment.hint_is_disabled ? '- Test Question (Hints and Peeks Disabled)' : ''}`}
                />
                <div className="assignments-font-size">{formatted}</div>
              </Form.Group>
            );
          })}
          <hr />
          <div className="d-flex justify-content-between">
            <p className="text-primary assignments-font-size">
              {`${chosenAssignmentIds.length} Assignments`}
            </p>
            <p className="text-primary assignments-font-size">{moment.duration(totalAverageTime, 'seconds').format('h[h] m[m] s[s]')}</p>
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-between mb-5">
          <Button className="button-style" variant="success" onClick={onAssignmentReturn}>
            Back
          </Button>
          <Button className="button-style" variant="success" type="submit">
            Save &amp; Next
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Assignments;
