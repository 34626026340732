import React from 'react';
import PropTypes from 'prop-types';
import ResetPassword from '../../components/reset-password';

const ResetPasswordScene = ({ history }) => (
  <ResetPassword history={history} message="Please choose a new password for your account." />
);

ResetPasswordScene.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default ResetPasswordScene;
