import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';

const Schools = ({ history }) => {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    apiWrapper.getSchools().then((res) => {
      setSchools(res.data);
    }).catch((error) => { console.log(error); });
  }, []);

  const onSchoolEdit = (school) => {
    history.push('/schools/edit', { school });
  };

  const renderSchools = () => schools.map((school) => {
    const {
      id, name, address, zip_code: zipCode, state_code: stateCode, city, country,
    } = school;
    return (
      <tr key={id}>
        <td className="align-middle">{name}</td>
        <td className="align-middle">{`${address}, ${city}, ${stateCode}, ${zipCode}, ${country}`}</td>
        <td>
          <Button variant="primary" size="sm" onClick={() => { onSchoolEdit(school); }}>
            Edit
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <MainLayout>
      <div className="d-flex justify-content-between mt-4">
        <h1> Schools </h1>
        <Button variant="success" className="align-self-center button-style" onClick={() => { history.push('/schools/new'); }}> Add School </Button>
      </div>
      <Table responsive className="mt-5">
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderSchools()}
        </tbody>
      </Table>
    </MainLayout>

  );
};

Schools.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default Schools;
