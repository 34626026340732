import React, { useState, useEffect } from 'react';
import JsPDF from 'jspdf';
import {
  Button,
  Table,
} from 'react-bootstrap';
import apiWrapper from '../../api';
import CouponLogo from '../../resources/images/sv_si_egrove_logo_navypng.png';
import MainLayout from '../../components/main-layout';
import './styles.css';

const CouponForm = ({ history }) => {
  const [coupons, setCoupons] = useState();

  useEffect(() => {
    apiWrapper.getAllCoupons().then((res) => {
      setCoupons(res.data);
    });
  }, []);

  const generatePdf = (coupon) => {
    const pdf = new JsPDF();
    let createAccount = pdf.splitTextToSize('4.  Create an account by entering your First Name, Last Name, Email Address, and Password. Then click "Sign up".', 220);
    let verifyAccount = pdf.splitTextToSize('5.  Verify your email address by logging into your account email. You should have a "Welcome to Spatial Vis!" email. Open the email and select the green "Confirm your account” button. If the button does not appear, copy and paste the URL at the bottom of your email.', 220);
    let logIn = pdf.splitTextToSize('6.  You will be redirected to log in to enroll in a course. Log In using your Email and Password from your new account and click submit.', 220);
    let useApp  = pdf.splitTextToSize('11. You may now log in to the Spatial Vis mobile app on your Chromebook, Tablet, or Phone using the Apple App or Google Play app. You may also log in from your computer by logging in on our website at https://egrove.education/.', 220); 
    pdf.addImage(CouponLogo, 'png', 40, 25, 130, 13);
    pdf.setFontSize(22);
    pdf.setFont('helvetica', 'bold');
    pdf.text(`Coupon Code: `, 20, 55);
    pdf.setFont('courier', 'bold');
    pdf.text(`${coupon.Code}`, 80, 55)
    pdf.setFontSize(13);
    pdf.setFont('helvetica', 'bold');
    pdf.text('Spatial Vis Coupon Activation Instructions', 20, 65).setFont(undefined, 'bold');;
    pdf.setFontSize(11);
    pdf.setFont('helvetica', 'normal');
    pdf.text('1.  Visit our website at https://egrove.education/.', 30, 75);
    pdf.text('2.  Click the Log In/Sign Up Button', 30, 80);
    pdf.text('3.  Click Sign Up', 30, 85);
    pdf.text(createAccount, 30, 90);
    pdf.text(verifyAccount, 30, 100);
    pdf.text(logIn, 30, 120);
    pdf.text('7.  Enter your Invite Code provided by your instructor.', 30, 130);
    pdf.text('8.  If the course looks correct, select "Checkout".', 30, 135);
    pdf.text('9.  Enter your Coupon Code provided above and select “Apply”.', 30, 140);
    pdf.text('10.  Select “Pay $0”.', 30, 145);
    pdf.text(useApp, 30, 150);
    pdf.text('If you have any questions or need assistance, please contact info@egrove.education', 20, 165);
    pdf.save(`${coupon.Code}.pdf`);
  };

  const renderCoupons = () => coupons?.map((coupon) => (
    <tr key={coupon.id}>
      <td>{coupon.Name}</td>
      <td>{coupon.Code}</td>
      <td>{`${coupon.Discount} %`}</td>
      <td>{coupon.Duration}</td>
      <td>{coupon.Expiration}</td>
      <td>
        {coupon.Used ? 'Yes' : 'No'}
        <Button
          id={coupon.id}
          variant="success"
          className="align-self-center button-style save-button"
          onClick={() => { generatePdf(coupon); }}
        >
            Download
        </Button>
      </td>
    </tr>
  ));

  const addCoupon = () => {
    history.push('/coupons/new-coupon');
  };

  return (
    <MainLayout>
      <div className="d-flex justify-content-between mt-4">
        <h1> Coupons </h1>
        <Button variant="success" className="align-self-center button-style" onClick={addCoupon}>
          Add Coupon
        </Button>
      </div>
      <Table responsive className="mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Discount</th>
            <th>Duration</th>
            <th>Expiration</th>
            <th>Used</th>
          </tr>
        </thead>
        <tbody>{renderCoupons()}</tbody>
      </Table>
    </MainLayout>
  );
};

CouponForm.propTypes = {
};

export default CouponForm;
