/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect, useState } from 'react';
import {
  Navbar, NavDropdown, Button, Form,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './styles.css';
import FullLogo from '../../resources/images/full_logo.svg';
import { authContext } from '../../contexts/auth-context';
import Modal from '../modal';

const WebAppNavBar = ({ stars, history }) => {
  const { setAuthData, auth } = useContext(authContext);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    setFirstName(auth.user.first_name);
    setLastName(auth.user.last_name);
  }, []);

  const onSignOut = () => setAuthData(null);

  const toggleModal = () => setModalState(!modalState);

  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const switchToTeacherInterfaceView = () => {
    history.push('/overview');
  };

  return (
    <>
      <Navbar expand="lg" variant="dark" className="web-navbar">
        <Navbar.Text className="stars-total mr-auto navbar-text">
          Stars: {stars}
        </Navbar.Text>
        <Navbar.Brand className="mr-auto" as={NavLink} to="/" style={{ marginLeft: auth.user.account_level === 'teacher' ? '17rem' : '5rem', marginRight: '0' }}>
          <img src={FullLogo} width="150" alt="Logo" />
        </Navbar.Brand>
        <Form inline>
          {auth.user.account_level === 'teacher'
            && (
            <Button
              variant="success"
              className="button-style"
              onClick={switchToTeacherInterfaceView}
            >
              Teacher Interface View
            </Button>
            )}
          <NavDropdown
            className="dropdown ml-auto navbar-text"
            title={`${firstName} ${lastName}`}
            id="icons"
            style={{ left: auth.user.account_level === 'teacher' ? '0' : '-20%' }}
          >
            {auth.user.account_level === 'student' && <NavDropdown.Item style={{ color: 'black' }} onClick={() => { history.push('/', { addNewEnrollment: true }); }}>Enroll in a new course</NavDropdown.Item> }
            <NavDropdown.Item style={{ color: 'black' }} onClick={toggleModal}>About Spatial Vis</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item style={{ color: 'black' }} onClick={onSignOut}>
              Log out
            </NavDropdown.Item>
          </NavDropdown>
        </Form>
      </Navbar>
      <Modal show={modalState} handleClose={toggleModal} close="button">
        <div className="about-modal">
          <h1>About Spatial Vis</h1>
          <h3>Spatial Vis Team</h3>
          <h6>Nathan Delson, PhD | CEO</h6>
          <h6>Lelli Van Den Einde, PhD | President</h6>
          <h6>Maxence Henneron | Lead Developer</h6>
          <h6>Seamgen | Software Development</h6>
          <h6>Elizabeth Cowan | Design</h6>
          <h6>Bob Mihelich | Curriculum Specialist</h6>
          <h6>Yash Kelkar | Software Engineer</h6>
          <h3>A Note From The Team</h3>
          <h5 className="message">We hope you are enjoying Spatial Vis! We are constantly improving the application and appreciate any feedback you may have.</h5>
          <h3>Patent Pending</h3>
          <h5>Copyright © 2017 - 2021 eGrove Education, Inc.</h5>
          <Button onClick={() => { handleClick('https://egrove.education/contact-us'); }} className="about-buttons">CONTACT US</Button>
          <Button onClick={() => { handleClick('https://egrove.education/'); }} className="about-buttons">EGROVE EDUCATION WEBSITE</Button>
          <Button onClick={() => { handleClick('https://egrove.education/terms-of-use'); }} className="about-buttons">TERMS OF USE</Button>
          <Button onClick={() => { handleClick('https://egrove.education/terms-of-use'); }} className="about-buttons">PRIVACY POLICY</Button>
        </div>
      </Modal>
    </>
  );
};

export default WebAppNavBar;
