import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useParams,
} from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import moment from 'moment';
import MainLayout from '../../components/main-layout';
import apiWrapper from '../../api';

const Gradings = () => {
  const [gradings, setGradings] = useState([]);
  const [course, setCourse] = useState({});
  const { id } = useParams();
  const HEADER_FIELDS = [
    'last_name',
    'first_name',
    'email',
    'user_id',
  ];

  useEffect(() => {
    apiWrapper.getStarGradings(id).then((res) => {
      setGradings(res.data);
    });

    apiWrapper.getCourse(id).then((res) => {
      setCourse(res.data);
    });
  }, [id]);

  const toCSV = (json) => {
    let csv = '';

    // CSV Header
    const keys = (json[0] && Object.keys(json[0])) || [];
    csv += `${keys.join(',')}\n`;

    // CSV Data
    json.forEach((line) => {
      csv += `${keys.map((key) => line[key]).join(',')}\n`;
    });

    return csv;
  };

  const getAllAssignments = async (newObject, grades, total) => {
    const arr = new Array(10).fill(0);
    for (let index = 0; index < arr.length; index++) {
      const response = await apiWrapper.getAssignments(index + 1);
      response.data.forEach(async (lesson) => {
        if (!isNaN(parseInt(lesson.code.split('_')[1]))) {
          const header = lesson.code.toLowerCase().indexOf('2d3d') >= 0 ? (`_${lesson.code.split('_')[0]}_${lesson.display_order}`).toLowerCase()
            : `${lesson.code.split('_')[0]}_${lesson.display_order}`;
          if (!newObject[header]) {
            const grade = grades.find((item) => item.email === newObject.email);
            if (grade[header]) {
              newObject = {
                ...newObject,
                [header]: grade[header],
              };
              total += grade[header];
            } else {
              newObject = { ...newObject, [header]: '' };
            }
          }
        }
      });
    }
    return newObject;
  }

  const starsForAllAssignments = (grades) => grades.map(async (grade) => {
    let newObject = {};
    let total = 0;

    Object.keys(grade).forEach((key) => {
      if (HEADER_FIELDS.find((keyName) => keyName === key)) {
        newObject = {
          ...newObject,
          [key]: grade[key],
        };
        return;
      }
      newObject = {
        ...newObject,
        [key]: grade[key],
      };
      total += grade[key];
    });
    newObject = await getAllAssignments(newObject, grades, total);
    return newObject;
  });

  const downloadAsCSV = async () => {
    const starsPromise = await starsForAllAssignments(gradings)
    const stars = [];
    for (const item of starsPromise) {
      await item.then((res) => stars.push(res));
    }
    const csv = toCSV(stars);
    const encodedUri = window.encodeURI(`data:text/csv;charset=utf-8,${csv}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `stars_${course.code}_${moment().format()}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const renderData = () => gradings.map((grading) => (
    <tr key={grading.user_id}>
      <td>{grading.first_name}</td>
      <td>{grading.last_name}</td>
      <td>{grading.email}</td>
      <td>{grading.total}</td>
    </tr>
  ));

  return (
    <MainLayout>
      <h2 className="mt-4">
        Stars Per Assignment Grade Report
      </h2>

      <Button className="button-style" style={{ float: 'right' }} onClick={downloadAsCSV} variant="success">
        Download Detailed Summary
      </Button>

      <Table responsive bordered className="mt-1">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Stars</th>
          </tr>
        </thead>
        <tbody>
          {renderData()}
        </tbody>
      </Table>
    </MainLayout>
  );
};

Gradings.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Gradings;
