import React from 'react';
import PropTypes from 'prop-types';
import CouponLayout from '../../components/coupon-layout';
import apiWrapper from '../../api';

const NewCoupon = ({ history }) => (
  <CouponLayout history={history} title="Add a new coupon" submitFunction={apiWrapper.addCourse} />
);

NewCoupon.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default NewCoupon;
