import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Alert,
} from 'react-bootstrap';
import MainLayout from '../main-layout';

const UserLayout = ({
  title, submitFunction, history,
}) => {
  const [teacher, setTeacher] = useState({
    first_name: '', last_name: '', email: '',
  });

  const [error, setError] = useState();

  const onChangeValue = (e) => {
    setTeacher({ ...teacher, [e.target.name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitFunction(teacher).then(() => {
      history.replace('/users');
    }).catch((err) => {
      setError(err.response.data.error.message);
    });
  };


  return (
    <MainLayout>
      <h1 className="mt-4">
        {title}
      </h1>
      {error && (
        <Alert className="text-center mt-3 p-1" variant="danger">
          <small>{`${error}.`}</small>
        </Alert>
      )}
      <p className="text-muted mt-3">All fields marked with an asterisk (*) have to be filled in.</p>
      <Form onSubmit={onFormSubmit}>
        <Form.Row className="mt-3">
          <Form.Group as={Col} controlId="formFirstName">
            <Form.Label>First name*</Form.Label>
            <Form.Control name="first_name" value={teacher.first_name} onChange={onChangeValue} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formLastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control name="last_name" value={teacher.last_name} onChange={onChangeValue} required />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control name="email" type="email" value={teacher.email} onChange={onChangeValue} required />
        </Form.Group>
        <Row>
          <Col />
          <Col>
            <Button variant="success" className="w-100 mt-3 button-style" type="submit">
            Submit
            </Button>
          </Col>
          <Col />
        </Row>

      </Form>
    </MainLayout>
  );
};

UserLayout.propTypes = {
  title: PropTypes.string.isRequired,
  submitFunction: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default UserLayout;
