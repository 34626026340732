/* eslint-disable import/prefer-default-export */
const serializeSvg = (lines) => {
  const maskBuilder = [];
  const svgBuilder = [];
  let mLastMaskId = 1;

  const addPath = (builder, points, strokeWidth, isMask) => {
    const pathBuilder = [];
    let isFirst = true;

    if (points.length <= 1) {
      return;
    }

    // Start path
    pathBuilder.push('<path d="');

    // add all points
    points.forEach((point) => {
      pathBuilder.push(`${isFirst ? 'M' : 'L'}${Math.round(point.x)} ${Math.round(point.y)} `);
      isFirst = false;
    });

    // end path
    pathBuilder.push(`" stroke-width="${strokeWidth}" stroke-linejoin="round" stroke-linecap="round" fill="none" stroke="#000" />\n`);

    if (isMask) {
      builder.push(pathBuilder.join(''));
    } else {
      builder.unshift(pathBuilder.join(''));
    }
  };

  const addMask = (points, strokeWidth) => {
    maskBuilder.push(`<mask id="${mLastMaskId}">\n`);
    maskBuilder.push('<rect x="0" y="0" width="640" height="640" fill="white" />\n');
    addPath(maskBuilder, points, strokeWidth, true);
    maskBuilder.push('</mask>');

    svgBuilder.unshift(`<g mask="url(#${mLastMaskId})">`);
    svgBuilder.push('</g>');

    mLastMaskId += 1;
  };

  lines.forEach((line) => {
    if (line.drawingMode === 'DRAW') {
      addPath(svgBuilder, line.path, line.strokeWidth, false);
    } else {
      addMask(line.path, line.strokeWidth);
    }
  });

  return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640">
<defs>
  ${maskBuilder.join('')}
</defs>
${svgBuilder.join('')}
</svg>`;
};

export { serializeSvg };
