import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import {
  useParams,
} from 'react-router-dom';
import CheckoutForm from './checkout-form';
import Logo from '../../resources/images/logo.svg';

import './styles.css';
import apiWrapper from '../../api';

const stripeApiKey = process.env.REACT_APP_STRIPE_KEY;

const Payment = ({ history }) => {
  const { code } = useParams();
  const [courseInformation, setCourseInformation] = useState({});

  useEffect(() => {
    apiWrapper.getPrice(code).then((res) => {
      setCourseInformation({
        name: res.data.name,
        price: res.data.price,
      });
    });
  }, [code]);

  return (
    <div className="payment-container">
      <div className="form-container">
        <div className="course-name-container">
          <div className="text-center">
            <div className="course-name">
              {courseInformation.name}
            </div>
            <div className="text-light font-italic course-name-description"> Course name</div>
          </div>
          <img src={Logo} className="logo" alt="Logo" />
        </div>
        <div className="card-info-container">
          <StripeProvider
            apiKey={stripeApiKey}
          >
            <Elements>
              <CheckoutForm price={courseInformation.price} code={code} history={history} />
            </Elements>
          </StripeProvider>
        </div>

      </div>
    </div>
  );
};

Payment.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default Payment;
