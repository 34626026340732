import React from 'react';
import PropTypes from 'prop-types';
import ResetPassword from '../../components/reset-password';
import { useParams } from 'react-router-dom';

const UsernameResetPassword = ({ history }) => {
    const { page, username, id } = useParams();
    return (  
        <ResetPassword history={history} message={"Please choose a new password for " + username + "."} enrollment_type="username" userInfo={{username, id}}/>
    );
}

UsernameResetPassword.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default UsernameResetPassword;