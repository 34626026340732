/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import WebAppLessonNavBar from '../navbar/web-app-lesson';

const WebAppLessonLayout = ({
  setAssignmentNumber, getTimeSpentOnTask, assignments, assignment, currentIndex, isSmallerScreen, location, history, children, timeToSketch, didStartDrawing,
}) => (
  <div style={{ textAlign: '-webkit-center', height: '100%' }}>
    <WebAppLessonNavBar
      setAssignmentNumber={setAssignmentNumber}
      getTimeSpentOnTask={getTimeSpentOnTask}
      assignments={assignments}
      assignment={assignment}
      currentIndex={currentIndex}
      isSmallerScreen={isSmallerScreen}
      location={location}
      history={history}
      didStartDrawing={didStartDrawing}
    />
    {children}
  </div>
);

WebAppLessonLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WebAppLessonLayout;
