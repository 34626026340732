import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Alert,
} from 'react-bootstrap';
import apiWrapper from '../../api';
import MainLayout from '../main-layout';

const CouponLayout = ({ history }) => {
  const [couponInformation, setCouponInformation] = useState({
    name: '', discount: 0, expiration: 1, duration: 'once', count: 1,
  });
  const [error, setError] = useState();

  const onChangeValue = (e) => {
    setCouponInformation({ ...couponInformation, [e.target.name]: e.target.value });
  };

  const onChangeValueInt = (e) => {
    if (e.target.value === '') setCouponInformation({ ...couponInformation, [e.target.name]: parseInt('0') });
    else setCouponInformation({ ...couponInformation, [e.target.name]: parseInt(e.target.value) });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    apiWrapper.createCoupon(couponInformation).then(() => {
      history.push('/coupons');
    }).catch((err) => {
      setError(err.response.data.error.message);
    });
  };

  return (
    <MainLayout>
      <h1 className="mt-4">
      </h1>
      {error && (
        <Alert className="text-center mt-3 p-1" variant="danger">
          <small>{`${error}.`}</small>
        </Alert>
      )}
      <p className="text-muted mt-3">All fields marked with an asterisk (*) have to be filled in.</p>
      <Form onSubmit={onFormSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formName">
            <Form.Label>Name*</Form.Label>
            <Form.Control name="name" placeholder="Coupon name" value={couponInformation.name} onChange={onChangeValue} required />
          </Form.Group>
          <Form.Group as={Col} controlId="formDiscount">
            <Form.Label>Discount* (%)</Form.Label>
            <Form.Control name="discount" value={couponInformation.discount} onChange={onChangeValueInt} />
          </Form.Group>
          <Form.Group as={Col} controlId="formCount">
            <Form.Label>Number of coupons*</Form.Label>
            <Form.Control name="count" value={couponInformation.count} onChange={onChangeValueInt} />
          </Form.Group>
        </Form.Row>
        <Row>
          <Col />
          <Col>
            <Button variant="success" className="w-100 mt-4 mb-5 button-style" type="submit">
            Submit
            </Button>
          </Col>
          <Col />
        </Row>

      </Form>
    </MainLayout>
  );
};

CouponLayout.propTypes = {
  title: PropTypes.string.isRequired,
  submitFunction: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    teachers: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    teacherIds: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

CouponLayout.defaultProps = {
  defaultValues: null,
};

export default CouponLayout;
