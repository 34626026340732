/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import './styles.css';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import NavPanel from '../nav-panel';
import { webAppContext } from '../../contexts/web-app-context';
import { currentSketch, currentSketchTime, setCurrentSketchTime } from '../../utils/web-app';
import { serializeSvg } from '../../utils/svg-serializer';
import apiWrapper from '../../api';
import getDefaultSubmission from '../../utils/get-default-submission';

const WebAppLessonNavBar = ({
  setAssignmentNumber, getTimeSpentOnTask, assignments, assignment, currentIndex, isSmallerScreen, location, history, didStartDrawing,
}) => {
  const [currentAssignment, setCurrentAssignment] = useState(currentIndex + 1);
  const {
    submissions, updateSubmission, enrollment,
  } = useContext(webAppContext);
  const submission = submissions?.find((sub) => sub.lesson_assignment_id === assignment?.id) ?? getDefaultSubmission(enrollment?.id, assignment?.id);

  const submitAssignment = (fromClose) => {
    if (didStartDrawing) {
      apiWrapper.submitAssignment({
        ...submission,
        did_peek: false,
        did_look_at_hint: false,
        sketch_svg: serializeSvg(currentSketch),
        sketched_at: moment().format(),
        created_at: moment(submission?.created_at).format(),
        updated_at: moment(submission?.updated_at).format(),
        was_submitted: false,
        sketch_time: currentSketchTime,
        time_on_task: getTimeSpentOnTask(),
      }).then((rawSubmission) => {
        const responseSubmission = rawSubmission.data;
        updateSubmission({
          ...responseSubmission,
        });
        setCurrentSketchTime(0);
        if (fromClose) history.push('/spatial-vis-web');
      });
    } else if (fromClose) history.push('/spatial-vis-web');
  };

  const updateAssignmentNumber = (number, fromNavigate = false) => {
    if (fromNavigate && submission && !submission.is_correct && assignment && assignment.kind !== 'tutorial') submitAssignment();
    setCurrentAssignment(number);
    setAssignmentNumber(number - 1);
  };

  const onCloseLesson = () => {
    document.title = 'Spatial Vis | eGrove education';
    if (submission && !submission.is_correct && assignment && assignment.kind !== 'tutorial') submitAssignment(true);
    else history.push('/spatial-vis-web');
  };

  useEffect(() => {
    if (currentIndex + 1 <= assignments.length) setCurrentAssignment(currentIndex + 1);
  }, [currentIndex, currentAssignment]);

  useEffect(() => {
    const currentSubmissions = submissions?.filter((sub) => assignments.some((item) => item.id === sub.lesson_assignment_id));
    const incompleteAssignments = currentSubmissions.filter((item) => !item.is_correct);
    const completeAssignments = currentSubmissions.filter((item) => item.is_correct);
    const solidAssignments = assignments.filter((item) => item.kind !== 'tutorial');
    const remainingAssignments = solidAssignments.filter((item) => !currentSubmissions.some((sub) => sub.lesson_assignment_id === item.id));
    const isFirstAssignmentDone = currentSubmissions.some((item) => item.lesson_assignment_id === solidAssignments[0].id && item.is_correct);
    if (!isFirstAssignmentDone || completeAssignments.length === solidAssignments.length) updateAssignmentNumber(1);
    else if (currentSubmissions.length === solidAssignments.length) {
      const introAssignments = assignments.filter((item) => item.kind === 'tutorial');
      if (completeAssignments.length < solidAssignments.length) {
        updateAssignmentNumber(completeAssignments.length + introAssignments.length + 1);
      }
    } else if (incompleteAssignments.length === 0 && currentSubmissions.length !== solidAssignments.length) {
      let minIncompleteId = Number.MAX_SAFE_INTEGER;
      remainingAssignments.forEach((item) => {
        if (item.id < minIncompleteId && item.kind !== 'tutorial') minIncompleteId = item.id;
      });
      const nextAssignmentIdx = assignments.findIndex((item) => minIncompleteId === item.id) + 1;
      nextAssignmentIdx === 0 ? updateAssignmentNumber(1) : updateAssignmentNumber(nextAssignmentIdx);
    } else if (incompleteAssignments.length === 0 && currentSubmissions.length > 0) {
      let maxSubmissionId = -1;
      currentSubmissions.forEach((item) => {
        if (item.lesson_assignment_id > maxSubmissionId && item.kind !== 'tutorial') maxSubmissionId = item.lesson_assignment_id;
      });
      const nextAssignmentIdx = assignments.findIndex((item) => maxSubmissionId === item.id) + 2;
      nextAssignmentIdx === 0 ? updateAssignmentNumber(1) : updateAssignmentNumber(nextAssignmentIdx);
    } else if (incompleteAssignments.length > 0) {
      let minIncompleteId = Number.MAX_SAFE_INTEGER;
      if (remainingAssignments.length > 0 || solidAssignments.some((item) => !item.is_correct)) {
        const remainingSolidAssignments = solidAssignments.filter((item) => !item.is_correct);
        remainingSolidAssignments.forEach((item) => {
          if (item.display_order < minIncompleteId) minIncompleteId = item.display_order;
        });
        minIncompleteId === 0 ? updateAssignmentNumber(1) : updateAssignmentNumber(minIncompleteId);
      } else {
        incompleteAssignments.forEach((item) => {
          if (item.lesson_assignment_id < minIncompleteId && item.kind !== 'tutorial') minIncompleteId = item.lesson_assignment_id;
        });
        const nextAssignmentIdx = assignments.findIndex((item) => minIncompleteId === item.id) + 1;
        nextAssignmentIdx === 0 ? updateAssignmentNumber(1) : updateAssignmentNumber(nextAssignmentIdx);
      }
    }
  }, [assignments]);

  return (
    <div>
      <Navbar
        history={history}
        style={{
          width: isSmallerScreen && assignment?.kind !== 'tutorial' && '33.5%',
          left: isSmallerScreen && assignment?.kind !== 'tutorial' && '-33.5%',
        }}
        expand="lg"
        variant="dark"
        className="web-navbar"
      >
        <Button aria-label="Close" className="mr-auto close-button" variant="raised" onClick={() => { onCloseLesson(); }}>
          <CloseIcon fontSize="medium" id="icons" />
        </Button>
        <div className="lesson-info">
          <Button aria-label="Previous assignment" disabled={currentAssignment === 1} variant="raised" onClick={() => { updateAssignmentNumber(currentAssignment - 1, true); }}>
            <KeyboardArrowLeftIcon fontSize="large" id="icons" />
          </Button>
          <h1 className="stars-total navbar-text" style={{ marginLeft: '0', marginRight: '0' }}>
            {!isSmallerScreen && (`Lesson ${location.state.index}: `)}
            {`${isSmallerScreen && assignment?.kind !== 'tutorial' ? location.state.lesson.short_name : location.state.lesson.name} - ${currentAssignment}/${assignments.length}`}
          </h1>
          <Button aria-label="Next assignment" disabled={currentAssignment === assignments.length} variant="raised" onClick={() => { updateAssignmentNumber(currentAssignment + 1, true); }}>
            <KeyboardArrowRightIcon fontSize="large" id="icons" />
          </Button>
        </div>
        <NavPanel
          goToAssignment={updateAssignmentNumber}
          currentIndex={currentIndex}
          isSmallerScreen={isSmallerScreen && assignment?.kind !== 'tutorial'}
          location={location}
          history={history}
        />
      </Navbar>
    </div>
  );
};

export default WebAppLessonNavBar;
