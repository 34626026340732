import React, { useEffect, useState, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import { Button } from 'react-bootstrap';
import { webAppContext } from '../../contexts/web-app-context';
import './styles.css';

const NavPanel = ({
  goToAssignment, currentIndex, isSmallerScreen, location,
}) => {
  const [assignments, setAssignments] = useState([]);
  const [sidebar, setSideBar] = useState(false);
  const { submissions, requiredAssignments, enrollment } = useContext(webAppContext);
  const [assignmentStars, setAssignmentStars] = useState([]);
  const showSideBar = () => setSideBar(!sidebar);

  const changeAssignment = (index) => {
    if (currentIndex + 1 !== index) goToAssignment(index, true);
    showSideBar();
  };

  useEffect(() => {
    getStarsEarned();
  }, [assignments]);

  useEffect(() => {
    if (location.state && location.state.lesson && location.state.assignments) {
      const currentAssignments = location.state.assignments.filter((item) => item.code.split('_')[0] === location.state.lesson.code).sort((a, b) => a.display_order - b.display_order);
      setAssignments(currentAssignments);
      currentAssignments.forEach((assignment) => {
        const submission = submissions.find((sub) => sub.lesson_assignment_id === assignment.id);
        assignment.is_correct = submission && submission.is_correct;
      });
    }
  }, [location.state, submissions]);

  const getStars = (submission) => {
    if (!submission?.is_correct) return '';
    if (submission.is_correct) {
      if (submission.did_peek) return 1;
      if (submission.did_look_at_hint) return 2;
      if (!submission.did_peek && !submission.did_look_at_hint) return 3;
    }
  };

  const getStarsEarned = () => {
    const currentStars = {};
    assignments.forEach((ass) => {
      if (ass.kind === 'solid' || ass.kind === 'hidden') {
        const assignmentSubmission = submissions.find((sub) => sub.lesson_assignment_id === ass.id);
        const stars = getStars(assignmentSubmission);
        if (ass.code.split('_')[0] === '2D3D') currentStars[`_2d3d_${ass.display_order}`] = stars;
        else currentStars[ass.display_order] = stars;
      }
    });
    setAssignmentStars(currentStars);
  };

  const renderStars = (item) => {
    const numStars = Array(assignmentStars[item.display_order]).fill(0);
    return (
      <div
        style={{
          position: 'absolute', bottom: '0', right: '0',
        }}
      >
        { numStars.map(() => (
          <StarIcon
            fontSize="medium"
            style={{
              color: '#f7b727',
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="nav-panel">
        <Button aria-label="Menu" className={isSmallerScreen ? 'smaller-screen-menu-btn' : 'menu-bars'} variant="raised" onClick={() => { showSideBar(); }}>
          <MenuIcon fontSize="medium" id="icons" />
        </Button>
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className="nav-menu-items" style={{ height: `${100 * (assignments.length + 10)}px` }}>
          <li className="navbar-toggle">
            <Button aria-label="Close" className="menu-bars close-button" variant="raised" onClick={() => { showSideBar(); }}>
              <CloseIcon fontSize="medium" id="icons" />
            </Button>
          </li>
          {assignments.map((item, index) => (
            <li key={index} className={item.kind === 'tutorial' ? 'nav-text intro-text' : 'nav-text'}>
              <Link tabIndex={sidebar ? 0 : -1} onClick={(event) => { event.preventDefault(); changeAssignment(index + 1); }} to="/">
                <span>{index + 1}</span>
                {item.kind === 'tutorial' ? <span>Introduction</span> : (
                  <div
                    className="content-image"
                    style={{ position: 'relative', border: !item.is_correct ? 'none' : 'solid' }}
                  >
                    <img
                      alt={`Assignment ${index}`}
                      src={item.content_image_uri}
                    />
                    {item.is_correct && (
                      <>{renderStars(item)}</>
                    )}
                    { enrollment?.course?.assignment_set_id !== 0

                      && !(requiredAssignments == null
                      || requiredAssignments.length <= 0
                      || requiredAssignments.find((assId) => assId === assignments[index]?.id) != null) && (
                      <div style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: 0,
                        top: 0,
                        opacity: 0.4,
                        backgroundColor: 'black',
                      }}
                      />
                    )}
                  </div>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default NavPanel;
