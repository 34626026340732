import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import {
  Button, Form, Alert, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FullLogo from '../../resources/images/full_logo.svg';
import FormInput from '../../components/form-input';
import apiWrapper from '../../api';
import { authContext } from '../../contexts/auth-context';

const LogIn = ({ history, location: { state } }) => {
  const { setAuthData } = useContext(authContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [SSOToken, setSSOToken] = useState('');
  const onSubmit = (e) => {
    setIsLoginLoading(true);
    e.preventDefault();
    apiWrapper
      .onAuthentication(email.trim(), password)
      .then((res) => {
        setAuthData(res.data);
        setIsLoginLoading(false);
        history.replace('/');
      })
      .catch((err) => {
        setIsLoginLoading(false);
        setError(err.response.data.error.message);
      });
  };

  const onValueChange = (value, type) => {
    switch (type) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="main-container">
      <div className="form-width">
        <img src={FullLogo} className="w-100" alt="Logo" />
        {
          state && state.flash && typeof(state.flash) === 'string' ? (
            <Alert className="text-center mt-3 p-1" variant="success">
              <small>{`${state.flash}`}</small>
            </Alert>
          ) : (
            <>{state && state.flash && typeof(state.flash) === 'object' && (
              <Alert className="text-center mt-3 p-1" variant="success">
              <p>{`${state.flash.first}`}</p>
              <p>{`${state.flash.second}`}</p>
            </Alert>)}</>
          )
        }
        {error && (
          <Alert className="text-center mt-3 p-1" variant="danger">
            <small>{`${error}.`}</small>
          </Alert>
        )}
        <Form onSubmit={onSubmit} className="w-100 mt-3">
          <FormInput
            title="Email address"
            type="text"
            id="email"
            value={email}
            placeholder="Enter your email"
            onValueChange={onValueChange}
          />
          <FormInput
            title="Password"
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            onValueChange={onValueChange}
          />
          <Link style={{ float: 'left', color: 'white' }} to="/sign-up">Sign Up</Link>
          <Link style={{ float: 'right', color: 'white' }} to="/sign-in/forgotten">Forgot Password?</Link>
          <Button variant="success" className="w-100 mt-3 button-style" type="submit">
            Submit &nbsp;
            {isLoginLoading && (
            <Spinner size="sm" animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

LogIn.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default LogIn;
