import React, { useState, useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';

import { Alert } from 'react-bootstrap';
import FullLogo from '../../resources/images/full_logo.svg';
import apiWrapper from '../../api';

const AccountActivation = ({ history }) => {
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { id, key } = useParams();

  useEffect(() => {
    apiWrapper.activateAccount(id, key).then(() => {
      setMessage('Thank you for confirming your account! Please log in below to add your course Invite Code and complete your enrollment.');
      history.replace('/sign-in', { flash: 'Thank you for confirming your account! Please log in below to add your course Invite Code and complete your enrollment.' });
    }).catch((err) => {
      setError(err.response.data.error ? err.response.data.error.message : 'Unknown error');
    });
  }, [id, key]);

  return (
    <div className="main-container">
      <div className="form-width">
        <img src={FullLogo} className="w-100" alt="Logo" />
        {error && (
          <Alert className="text-center mt-3 p-1" variant="danger">
            <small>{`${error}.`}</small>
          </Alert>
        )}
        {message && (
          <Alert className="text-center mt-3 p-1" variant="success">
            <small>{message}</small>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default AccountActivation;
