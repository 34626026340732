/* eslint-disable max-len */
import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment-duration-format';

const AssignmentSummary = ({ assignmentInfo }) => {
  useEffect(() => {
    if (assignmentInfo && assignmentInfo.length > 0) assignmentInfo.sort((a, b) => a.lesson.display_order - b.lesson.display_order);
  });
  const renderAssignmentInfo = () => assignmentInfo.map((assignment, index) => (
    <div className="d-flex justify-content-between" key={assignment.lesson.id}>
      <div className="d-flex flex-1">
        <p className="text-capitalize assignments-font-size">{`L${index}: ${assignment.lesson.name}`}</p>
      </div>
      <div className="d-flex flex-1 justify-content-center">
        <p className="assignments-font-size">{assignment.ids.length}</p>
      </div>
      <div className="d-flex flex-1 justify-content-center">
        <p className="assignments-font-size">{moment.duration(assignment.averageTime, 'seconds').format('h[h] m[m] s[s]')}</p>
      </div>
    </div>
  ));

  const renderTotal = () => {
    const assignmentsCount = assignmentInfo.reduce((accumulator, assignment) => accumulator + assignment.ids.length, 0);
    const averageTimeCount = assignmentInfo.reduce((accumulator, assignment) => accumulator + assignment.averageTime, 0);
    return (
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex flex-1 justify-content-center">
          <b className="text-primary text-capitalize assignments-font-size">{`${assignmentsCount} assignments`}</b>
        </div>
        <div className="d-flex flex-1 justify-content-center" />
        <div className="d-flex flex-1 justify-content-center">
          <b className="text-primary assignments-font-size">{moment.duration(averageTimeCount, 'seconds').format('h[h] m[m] s[s]')}</b>
        </div>
      </div>
    );
  };

  return (
    <>
      <b className="assignments-font-size">Custom Assignment Set Summary</b>
      <div className="assignments-container mt-2">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-1 justify-content-center">
            <b className="text-primary text-decoration assignments-font-size">Lesson</b>
          </div>
          <div className="d-flex flex-1 justify-content-center">
            <b className="text-primary text-decoration assignments-font-size"># Assignments</b>
          </div>
          <div className="d-flex flex-1 justify-content-center">
            <b className="text-primary text-decoration assignments-font-size">Avg Time</b>
          </div>
        </div>
        {renderAssignmentInfo()}
        <hr />
        {renderTotal()}
      </div>
    </>
  );
};

export default AssignmentSummary;
