import React, { useState, useEffect } from 'react';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';

const NameForm = ({ onFormSubmitted, defaultName, preset }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (defaultName) {
      setName(defaultName);
    }
  }, [defaultName]);


  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onNameSubmit = (e) => {
    e.preventDefault();
    onFormSubmitted(name);
  };

  return (
    <>
      <Row className="mt-5">
        <Col>
          <h5>1. Name your Custom Assignment Set (ex. Engr 101, Graphics, Even Assignments, etc.)</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} lg={6}>
          <Form onSubmit={onNameSubmit}>
            <Form.Group>
              <Form.Control type="text" onChange={onNameChange} value={name && name} disabled={preset}/>
            </Form.Group>
            <Button className="button-style" variant="success" type="submit">
              Save 	&amp; Next
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NameForm;
